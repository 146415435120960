import { invalidTokenJWT } from "../contexts/AuthContext";
import { Project, ResultAndResponse } from "../types";
import { api, headerBearer } from "./api"

interface ProjectResponseType extends ResultAndResponse { data?: Project };
interface ProjectsResponseType extends ResultAndResponse { data?: Project[] };
interface CacheProjectsType {
  token: string,
  data: ProjectsResponseType,
  show_inactivated: boolean
}
const cacheProjects : CacheProjectsType[]= [];
const clearCacheProjects = () => cacheProjects.splice(0, cacheProjects.length);
export const getProjects = async (token: string, show_inactivated: boolean = false) : Promise<ProjectsResponseType> => {
  try{
    let findedCache = cacheProjects.find(cache => cache.token === token && cache.show_inactivated === show_inactivated);
    if(findedCache && findedCache.data.result){
      console.log('[cache-projects]');
      return findedCache.data;
    }
  }catch(e){ console.error(e); }

  try{
    const { data } = await api.get<Project[]>(`/projects${show_inactivated ? '/1': ''}`, headerBearer(token));
    console.log('[request-project]')
    const response = {
      result: true,
      response: 'Lista de projetos',
      data
    };
    
    cacheProjects.push({ token, data: response, show_inactivated });
    return response;
  }
  catch(e: any){
    if(e?.response?.data?.message === 'Invalid JWT token') invalidTokenJWT();

    return {
      result: false,
      response: 'Houve um erro ao solicitar os serviços'
    };
  }
}
export const toggleActiveProject = async (project_id: string, is_activate: boolean,  token: string) : Promise<ProjectResponseType> => {
  try{
    const { data } = await api.put(`toggle-active-project/${project_id}`, { is_activate }, headerBearer(token));

    clearCacheProjects();

    return {
      result: true,
      response: is_activate ? 'Projeto habilitado' : 'Projeto desabilitado',
      data
    };
  }catch(e: any){
    console.error(e);
    let response = 'Houve um erro inesperado';
    if(e?.response?.data?.message) response = e.response.data.message;
    else if(e?.response?.data && typeof e?.response?.data === 'string') response = e.response.data;

    return {
      result: false,
      response
    }
  }
}
export const createProject = async (name: string, token: string) : Promise<ProjectResponseType> => {
  try{
    const { data } = await api.post('/project', { name }, headerBearer(token));

    clearCacheProjects();

    return {
      result: true,
      response: 'Projeto criado com sucesso',
      data
    };
  }catch(e: any){
    console.error(e);
    let response = 'Houve um erro inesperado';
    if(e?.response?.data?.message) response = e.response.data.message;
    else if(e?.response?.data && typeof e?.response?.data === 'string') response = e.response.data;

    return {
      result: false,
      response
    }
  }
}