import { Accordion, Badge, Spinner, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import { OrderDetails } from "../../components/Order/OrderDetails";
import { Wrapper } from "../../components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { formatRows, getOrderWithWarning, RulesType } from "../../services/order";
import { OrderFormattedType } from "../../types";

// #region DECLARE TYPES
type WarningTypeOnDetailsType = 'warning' | 'expired' | 'now';
interface TableOrdersType{
  orders: OrderFormattedType[] | undefined,
  textEmpty: string
  onDetails: (order: OrderFormattedType, type: WarningTypeOnDetailsType) => void,
  type: WarningTypeOnDetailsType
}
// #endregion DECLARE TYPES

const TableOrders = ({ orders, textEmpty, onDetails, type } : TableOrdersType) => (
  <Table>
    <Table.Head>
      <Table.HeadCell>Nª da PO</Table.HeadCell>
      <Table.HeadCell>Descrição</Table.HeadCell>
      <Table.HeadCell>Status</Table.HeadCell>
      <Table.HeadCell>Criação</Table.HeadCell>
      <Table.HeadCell>Atualização</Table.HeadCell>
      {['expired','warning'].includes(type) && (
        <Table.HeadCell>
          {type === 'warning' ? 'Vence Em' : 'Vencido à'}
        </Table.HeadCell>
      )}
    </Table.Head>
    <Table.Body className="divide-y">
      {orders ? ( orders.length === 0 ? (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell colSpan={['expired','warning'].includes(type) ? 6 : 5} className="text-center py-8 text-gray-400">
            {textEmpty}
          </Table.Cell>
        </Table.Row>
      ):(
        orders.map((order) => (
          <Table.Row 
            className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer"
            onClick={() => onDetails(order, type)}
            key={order.id}
          >
            <Table.Cell>
              <span className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {order.poId}
              </span>
              <span className="block uppercase text-[10px] leading-none">
                {order.user}
              </span>
            </Table.Cell>
            <Table.Cell>{order.description ?? <span className="text-xs">-- sem descrição --</span>}</Table.Cell>
            <Table.Cell>{order.status}</Table.Cell>
            <Table.Cell>{order.date}</Table.Cell>
            <Table.Cell>{order.update}</Table.Cell>
            {['expired','warning'].includes(type) && order.remainingDays && (
              <Table.Cell>
                {Math.abs(order.remainingDays) == 1 ? '1 dia': `${Math.abs(order.remainingDays)} dias`}
              </Table.Cell>
            )}
          </Table.Row>
        ))
      )):(
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell colSpan={['expired','warning'].includes(type) ? 7 : 6} className="text-center py-8">
            <Spinner aria-label="Carregando dados"/>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);
export const Warning = () => {
  const { user } = useAuth();
  const [warningOrders, setWarningOrders] = useState<OrderFormattedType[]>();
  const [expiredOrders, setExpiredOrders] = useState<OrderFormattedType[]>();
  const [nowOrders, setNowOrders] = useState<OrderFormattedType[]>();

  const [rules, setRules] = useState<RulesType>();
  
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderFormattedType>();
  const [warningTypeOnDetails, setWarningTypeOnDetails] = useState<WarningTypeOnDetailsType>();

  useEffect(() => {
    (async () => {
      if(!user) return;

      const data = await getOrderWithWarning(user.token)
      setExpiredOrders(formatRows(
        data.expired
      ));
      setWarningOrders(formatRows(
        data.warning
      ));
      setNowOrders(formatRows(
        data.now
      ));
      setRules(data.rules);
    })()
  },[user]);

  function handleShowDetails(order: OrderFormattedType, type: WarningTypeOnDetailsType){
    setWarningTypeOnDetails(type);
    setCurrentOrder(order);
    setDetailsIsOpen(true);
  }
  function handleCloseDetails(){
    setDetailsIsOpen(false);
    setCurrentOrder(undefined);
    setWarningTypeOnDetails(undefined);
  }
  function handleUpdateOrder(order: OrderFormattedType){
    setCurrentOrder(order);
    const updateOrder = (orders?: OrderFormattedType[]) => {
      if(!orders) return orders;

      if(warningTypeOnDetails && rules){
        let days = order.remainingDays ?? 0;
        let removeWarning = false;
        switch (rules[warningTypeOnDetails].operation) {
          case 'equal': removeWarning = !(days === rules[warningTypeOnDetails].value); break;
          case 'lt':    removeWarning = !(days < rules[warningTypeOnDetails].value); break;
          case 'gt': removeWarning = !(days > rules[warningTypeOnDetails].value); break;
          case 'lte': removeWarning = !(days <= rules[warningTypeOnDetails].value); break;
          case 'gte': removeWarning = !(days >= rules[warningTypeOnDetails].value); break;
        }
        if(removeWarning) return orders.filter(o => o.id !== order.id);
      }
      
      let index = orders?.findIndex(o => o.id === order.id);
      if(index > -1) orders[index] = order;

      return orders;
    };

    if(!warningTypeOnDetails) return;
    switch(warningTypeOnDetails){
      case 'warning': setWarningOrders(updateOrder);  break;
      case 'expired': setExpiredOrders(updateOrder);  break;
      case 'now':     setNowOrders(updateOrder);      break;
    }
  }

  return (
    <Wrapper
      module_name="Contas a Pagar"
    >
      <>
        <div className="w-full">
          <Accordion alwaysOpen={true}>
            <Accordion.Panel>
              <Accordion.Title>
                <div className="flex gap-2">
                  Solicitações vencidas
                  <Badge className="bg-red-700 ">
                    <span className="text-gray-50">
                      { expiredOrders?.length ?? 0 }
                    </span>
                  </Badge>
                </div>
              </Accordion.Title>
              <Accordion.Content>
                <TableOrders
                  orders={expiredOrders}
                  textEmpty="Nenhuma solicitação vencida"
                  onDetails={handleShowDetails}
                  type="expired"
                />
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
              <Accordion.Title>
                <div className="flex gap-2">
                  Solicitações a vencer hoje
                  <Badge className="bg-gray-500">
                    <span className="text-gray-50">
                      { nowOrders?.length ?? 0}
                    </span>
                  </Badge>
                </div>
              </Accordion.Title>
              <Accordion.Content>
                <TableOrders
                  orders={nowOrders}
                  textEmpty="Nenhuma solicitação a vencer hoje"
                  onDetails={handleShowDetails}
                  type="now"
                />
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
              <Accordion.Title>
                <div className="flex gap-2">
                  Solicitações próximas do vencimento
                  <Badge className="bg-yellow-400">
                    <span className="text-gray-50">
                      {warningOrders?.length ?? 0 }
                    </span>
                  </Badge>
                </div>
              </Accordion.Title>
              <Accordion.Content>
              <TableOrders
                orders={warningOrders}
                textEmpty="Nenhuma solicitação próxima ao vencimento"
                onDetails={handleShowDetails}
                type="warning"
              />
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>
        <OrderDetails
          isOpen={detailsIsOpen}
          onClose={handleCloseDetails}
          onChange={handleUpdateOrder}
          order={currentOrder}
        />
      </>
    </Wrapper>
  );
}