import { StorageKeyType } from "../types";

// VERSION.FEATURE.BUG
const CURRENT_VERSION = '1.1.0';

export const storageKeys : StorageKeyType = {
  user: 'portalIvrim@user',
  token: 'portalIvrim@token',
  costCenters: 'portalIvrim@cache-const-centers',
  developers: 'portalIvrim@developers',
  version: 'portalIvrim@version'
};

export const checkVersion = () => {
  let version = localStorage.getItem(storageKeys.version);
  if(version && version !== CURRENT_VERSION){
    sessionStorage.clear();
    localStorage.clear();
    
    localStorage.setItem(storageKeys.version, CURRENT_VERSION);
    // @ts-ignore
    window.location.reload(true);
  }

  if(!version){
    version = CURRENT_VERSION;
    localStorage.setItem(storageKeys.version, CURRENT_VERSION);
  }

  console.log(`[portal-version-${version ?? 0}]`);
}