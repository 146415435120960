import { api } from "./api"

interface Email{
    nome: string,
    telefone: string,
    empresa: string,
    email: string
}
export const sendEmail = async(data: Email) => {
    try {
        await api.post('/send-email', data)

        return 
    } catch (error) {
        throw new Error('erro ao enviar email');
        
    }
    
 

}