import { FileInput, Label, Textarea } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";
import { approvalOrder, approvalOrderDocs, downloadZipOrder, formatRows, lastStatusOrder, uploadFormPO } from "../../services/order";
import { shortclass } from "../../styles/styles";
import { OrderFormattedType, PossiblePermissions } from "../../types";
import { Loading } from "../Loading";
import { ListIcon, MoreVerticalIcon, TableIcon } from "../SvgIcons";
import { Dropdown } from "../utils/Dropdown";
import { Modal } from "../utils/Modal";
import SlideOver from "../utils/SlideOver";
import { OrderHeader } from "./OrderHeader";
import { OrderSummary } from "./OrderSummary";
import { OrderTimeline } from "./OrderTimeline";

interface OrderDetailsType {
  isOpen: boolean,
  onClose: () => void,
  onChange: (order: OrderFormattedType) => void,
  onDelete?: (id: string, poId: string) => void,
  order?: OrderFormattedType
}
interface PermissionType {
  can_approve: boolean,
  can_reprove: boolean,
  can_upload_form: boolean,
  can_delete: boolean,
  can_change_docs: boolean,
  can_approve_docs: boolean,
  can_reprove_docs: boolean,
  can_confirm_bank_register: boolean,
  can_confirm_payment: boolean,
  can_download_zip: boolean
}
export const OrderDetails = ({
  isOpen,
  onClose,
  onChange,
  onDelete,
  order
}: OrderDetailsType) => {
  const { user } = useAuth();
  const { toast, showMessage, onCloseModal } = useNotify();
  const [loading, setLoading] = useState(false);
  const clearPermissions = () => ({
    can_approve: false,
    can_reprove: false,
    can_upload_form: false,
    can_delete: false,
    can_change_docs: false,
    can_approve_docs: false,
    can_reprove_docs: false,
    can_confirm_bank_register: false,
    can_confirm_payment: false,
    can_download_zip: false
  })
  const [permissions, setPermissions] = useState<PermissionType>(clearPermissions());
  const [file, setFile] = useState<File | null>(null);
  const [modalPage, setModalPage] = useState<'reproval' | 'reproval-docs' | null>(null)

  const [showMode, setShowMode] = useState<'summary' | 'timeline'>('summary');
  useEffect(() => {
    if (!order) return;

    setPermissions({
      can_approve: canApprove(order),
      can_reprove: canReprove(order),
      can_upload_form: canUploadForm(order),
      can_delete: canDelete(order),
      can_change_docs: canChangeDocs(order),
      can_approve_docs: canApproveFinance(order),
      can_reprove_docs: canReproveFinance(order),
      can_confirm_bank_register: canConfirmBankRegister(order),
      can_confirm_payment: canConfirmPayment(order),
      can_download_zip: canDownloadZip(order)
    });
  }, [order]);

  // #region HANDLE PERMISSION
  const canApprove = (order: OrderFormattedType) => {
    if (!user || !user.permitions_slug) return false;
    return user.permitions_slug.includes(PossiblePermissions.APPROVAL) &&
      (order.stageSlug === 'aprovacao' || order.statusSlug === 'reprovado');
  }
  const canReprove = (order: OrderFormattedType) => {
    if (!user || !user.permitions_slug) return false;
    return (
      (user.permitions_slug.includes(PossiblePermissions.APPROVAL) &&
        order.stageSlug === 'aprovacao' &&
        order.statusSlug !== 'reprovado') ||
      order.statusSlug === 'doc_pendente'
    );
  }
  const canUploadForm = (order: OrderFormattedType) => ((
    !order.src && order.stageSlug === 'solicitacao'
  ));
  const canDelete = (order: OrderFormattedType) => {
    if (!onDelete || !user) return false;
    if (['pagamento_executado', 'aguardando_registro_no_banco'].includes(order.stageSlug)) return false;
    if (order.stageSlug === 'aguardando_validacao_do_financeiro') {
      return !!(user.permitions_slug?.includes(PossiblePermissions.FINANCIAL_APPROVAL));
    }
    return true;
  }
  const canChangeDocs = (order: OrderFormattedType) => (
    ['aguardando_doc_para_pagamento', 'aguardando_validacao_do_financeiro'].includes(order.stageSlug)
  );
  const canApproveFinance = (order: OrderFormattedType) => {
    if (!user || !user.permitions_slug) return false;
    return (
      user.permitions_slug.includes(PossiblePermissions.FINANCIAL_APPROVAL) &&
      order.stageSlug === 'aguardando_validacao_do_financeiro'
    );
  }
  const canReproveFinance = (order: OrderFormattedType) => {
    if (!user || !user.permitions_slug) return false;
    return (
      user.permitions_slug.includes(PossiblePermissions.FINANCIAL_APPROVAL) &&
      ['aguardando_validacao_do_financeiro', 'aguardando_registro_no_banco'].includes(
        order.stageSlug
      ) && order.statusSlug !== 'doc_reprovado'
    );
  }
  const canConfirmBankRegister = (order: OrderFormattedType) => {
    if (!user || !user.permitions_slug) return false;
    return (
      user.permitions_slug.includes(PossiblePermissions.FINANCIAL_APPROVAL) &&
      order.stageSlug === 'aguardando_registro_no_banco'
    );
  }
  const canConfirmPayment = (order: OrderFormattedType) => {
    if (!user || !user.permitions_slug) return false;
    return (
      user.permitions_slug.includes(PossiblePermissions.FINANCIAL_APPROVAL) &&
      order.stageSlug === 'aguardando_pagamento'
    );
  }
  const canDownloadZip = (order: OrderFormattedType) => (
    order.stageSlug !== 'solicitacao'
  );
  // #endregion HANDLE PERMISSION

  function handleFile(e: any) {
    if (!e?.target?.files) return;
    setFile(e?.target?.files[0]);
  }
  async function confirmApproval(isApproval: boolean, isDocs: boolean) {
    const text = isDocs ? 'os documentos desta' : 'essa';
    const callback = (reprovalReason?: string) => isDocs ?
      handleApproveDocs(isApproval, reprovalReason) :
      handleApprove(isApproval, reprovalReason);
    const nodeApprove = (
      <p className="text-gray-700 mt-4 mb-2">Deseja aprovar {text} ordem?</p>
    );
    const nodeReprove = (
      <>
        <p className="text-gray-700 mt-4 mb-2">Deseja reprovar {text} ordem?</p>

        <Textarea
          id="confirm-reproval-reason"
          placeholder="Digite o motivo da reprovação..."
          rows={4}
          className="invalid:bg-red-50 invalid:border-red-500 invalid:text-red-900 invalid:placeholder-red-700 invalid:focus:ring-red-500 invalid:focus:border-red-500 invalid:dark:text-red-500 invalid:dark:placeholder-red-500 invalid:dark:border-red-500"
        />
      </>
    );
    if (isApproval) {
      showMessage((isApproval ? nodeApprove : nodeReprove),
        {
          title: `Confirmar Aprovação${isDocs ? ' de Documentos' : ''}`,
          cancelButtonText: 'Não',
          actionButton: {
            onClick: () => {
              if (isApproval) {
                callback();
                onCloseModal();
                return;
              }

              let el = document.getElementById('confirm-reproval-reason') as HTMLInputElement;
              if (!el) {
                toast.error('Não foi possível ler o motivo de reprovação');
                return;
              }
              if (!el.value || el.value.length === 0) {
                el.setCustomValidity('Preencha o motivo da reprovação');
                setTimeout(() => el.setCustomValidity(''), 3000);

                el.focus();
                return;
              }

              callback(el.value);
              onCloseModal();
            },
            text: 'Sim',
            theme: 'primary',
            autoClose: false
          }
        });
    } else {
      setModalPage(isDocs ? 'reproval-docs' : 'reproval');
    }
  }
  async function handleApprove(isApproval: boolean, reprovalReason?: string) {
    if (!user || !order || loading) return;

    if (!isApproval && !reprovalReason) {
      toast.warning('Descreva o motivo da reprovação');
      confirmApproval(isApproval, false);
      return;
    }

    setLoading(true);
    try {
      const res = await approvalOrder({
        orderId: order.id,
        isApproval,
        reprovalReason
      }, user.token);

      if (res.result && res.data) {
        toast.success(res.response);
        try {
          let formatted = formatRows([
            res.data
          ]);
          onChange(formatted[0]);
        }
        catch (e) {
          console.error(e);
          toast.warning('Recarrega a tela para atualizar a ordem');
        }
      } else {
        toast.error(res.response)
      }
    } catch (err) {
      toast.error(`Houve um erro inesperado`);
      console.error(err);
    }
    setLoading(false);
  }
  async function handleApproveDocs(isApproval: boolean, reprovalReason?: string) {
    if (!user || !order || loading) return;

    if (!isApproval && !reprovalReason) {
      toast.warning('Descreva o motivo da reprovação');
      confirmApproval(isApproval, true);
      return;
    }

    setLoading(true);
    try {
      const res = await approvalOrderDocs({
        orderId: order.id,
        isApproval,
        reprovalReason
      }, user.token);

      if (res.result && res.data) {
        toast.success(res.response);
        try {
          let formatted = formatRows([
            res.data
          ]);
          onChange(formatted[0]);
        }
        catch (e) {
          console.error(e);
          toast.warning('Recarrega a tela para atualizar a ordem');
        }
      } else {
        toast.error(res.response)
      }
    } catch (err) {
      toast.error(`Houve um erro inesperado`);
      console.error(err);
    }
    setLoading(false);
  }
  async function handleUploadForm() {
    if (!order || !user || loading) return;
    if (!file) {
      toast.warning('Selecione o arquivo que deseja fazer o upload');
      return;
    }

    setLoading(true);
    try {
      const data = new FormData();
      data.append('id', order.id);
      data.append('file', file)

      const res = await uploadFormPO(data, user.token);
      if (res.result && res.data) {
        toast.success(res.response);
        try {
          let formatted = formatRows([
            res.data
          ]);
          onChange(formatted[0]);
        }
        catch (e) {
          console.error(e);
          toast.warning('Recarrega a tela para atualizar a ordem');
        }
      } else {
        toast.error(res.response)
      }
    } catch (err) {
      toast.error(`Houve um erro inesperado`);
      console.error(err);
    }
    setLoading(false);
  }
  async function handleConfirmBankRegistration() {
    const callback = async () => {
      if (!user || !order || loading) return;
      setLoading(true);
      const data = await lastStatusOrder(order.id, user.token);
      if (data.result) {
        let needReload = true;
        try {
          if (data.data) {
            let formatted = formatRows([
              data.data
            ]);
            onChange(formatted[0]);
            needReload = false
          }
        } catch (e) {
          console.error(e);
          needReload = true;
        }

        if (needReload) toast.warning('Houve um erro ao recarregar os dados. Recarregue a tela para ver as alterações');
        else toast.success('Registro no banco confirmado');
        setLoading(false);
        return;
      }
      toast.error(data.response);
      setLoading(false);
    };

    showMessage((
      <p className="text-sm text-gray-700">Deseja confirmar registro no banco?</p>
    ), {
      title: 'Confirmar Registro no Banco',
      actionButton: {
        onClick: callback,
        theme: 'primary',
        text: 'Sim'
      },
      cancelButtonText: 'Não'
    })
  }
  async function handleConfirmPayment() {
    const callback = async () => {
      if (!user || !order || loading) return;

      setLoading(true);
      const data = await lastStatusOrder(order.id, user.token);
      setLoading(false);
      if (data.result) {
        let needReload = true;
        try {
          if (data.data) {
            let formatted = formatRows([
              data.data
            ]);
            onChange(formatted[0]);
            needReload = false
          }
        } catch (e) {
          console.error(e);
          needReload = true;
        }

        if (needReload) toast.warning('Houve um erro ao recarregar os dados. Recarregue a tela para ver as alterações');
        else toast.success('Pagamento confirmado');

        return;
      }
      toast.error(data.response);
    };

    showMessage((
      <p className="text-sm text-gray-700">Deseja confirmar pagamento?</p>
    ), {
      title: 'Confirmar Pagamento',
      actionButton: {
        onClick: callback,
        theme: 'primary',
        text: 'Sim'
      },
      cancelButtonText: 'Não'
    })
  }
  async function handleDownloadOrder(){
    if(!user || !order) return;
    console.log('[download-zip]');
    
    setLoading(true);
    const response = await downloadZipOrder(order.id, order.poId, user.token) as any;
    setLoading(false);


  }
  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="h-full flex flex-col justify-between relative">
        {order && (
          <>
            <div className="relative">
              <div className="absolute -top-9 left-0 right-0 flex items-center justify-center gap-2">
                <button
                  type="button"
                  className={`${ showMode ===  'summary' ? 'bg-gray-200':'' } border rounded-lg px-4 text-gray-800`}
                  onClick={() => setShowMode('summary')}
                ><TableIcon width="16"/></button>
                <button
                  type="button"
                  className={`${ showMode === 'timeline' ? 'bg-gray-200':'' } border rounded-lg px-4 text-gray-800`}
                  onClick={() => setShowMode('timeline')}
                ><ListIcon width="16"/></button>
              </div>
              <div className="flex justify-between items-center gap-2">
                <OrderHeader order={order} />
                {(
                  permissions.can_approve ||
                  permissions.can_reprove ||
                  permissions.can_delete ||
                  permissions.can_change_docs ||
                  permissions.can_approve_docs ||
                  permissions.can_reprove_docs ||
                  permissions.can_download_zip
                ) && (
                  <Dropdown
                    trigger={
                      <span className="hover-opacity text-gray-600 flex items-center">
                        <MoreVerticalIcon color="rgb(55 65 81 / var(--tw-text-opacity))"/>
                      </span>
                    }
                    classNames={{ wrapper: "inline-block text-left" }}
                    orientation={null}
                    styles={{
                      list: { transform: 'translateX(-12rem)' }
                    }}
                  >
                    {permissions.can_change_docs && (
                      <Link
                        className={shortclass.dropdownItem}
                        to={`/documentos-para-pagamento/${order.id}`}
                      >Alterar Documentos</Link>
                    )}
                    {permissions.can_approve && (
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={() => confirmApproval(true, false)}
                      >Aprovar</button>
                    )}
                    {permissions.can_reprove && (
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={() => confirmApproval(false, false)}
                      >Reprovar</button>
                    )}
                    {permissions.can_approve_docs && (
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={() => confirmApproval(true, true)}
                      >Aprovar Documentos</button>
                    )}
                    {permissions.can_reprove_docs && (
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={() => confirmApproval(false, true)}
                      >Reprovar Documentos</button>
                    )}
                    {permissions.can_download_zip && (
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={handleDownloadOrder}
                      >Baixar todos arquivos</button>
                    )}
                    {permissions.can_delete && (
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={() => onDelete!(order.id, order.poId)}
                      >Excluir</button>
                    )}
                  </Dropdown>
                )}
              </div>
              <div className="mt-6 mb-4">
                {order && (
                  <>
                    {order.data && (order.data.reprovalReason || order.data.reprovalDocReason) && (
                      <div className={`
                        border border-red-700/50 px-2 pt-[1.08rem] pb-1
                        bg-red-50 text-red-900/80 text-sm
                        rounded-lg relative
                      `}>
                        <span className="text-xs font-semibold opacity-90 absolute top-0 left-2">motivo de reprovação</span>
                        {order.data.reprovalReason || order.data.reprovalDocReason}
                      </div>
                    )}
                    {showMode === 'summary' ? (
                      <OrderSummary order={order!} />
                    ) : (
                      <OrderTimeline order={order!} />
                    )}
                  </>
                )}
                {permissions.can_upload_form && (
                  <div className="mt-4">
                    <FileInput
                      id="upload-form"
                      defaultValue={""}
                      className="border rounded-lg"
                      onChange={handleFile}
                    />
                    <p className="mt-1 ml-2 text-sm text-gray-500">Você ainda não fez o upload do formulário</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex flex-col gap-2">
              {order.stageSlug === 'aprovacao' ? (
                <>
                  {permissions.can_approve && (
                    <button
                      type="button"
                      className={shortclass.button.primary}
                      onClick={() => confirmApproval(true, false)}
                    >Aprovar</button>
                  )}
                  {permissions.can_reprove && (
                    <button
                      type="button"
                      className={`
                        ${shortclass.button.normal}
                        bg-gray-50 border-red-700 text-red-700 
                        focus:ring-red-500 hover:bg-red-700
                        hover:text-gray-50
                      `}
                      onClick={() => confirmApproval(false, false)}
                    >Reprovar</button>
                  )}
                </>
              ) : order.stageSlug === 'aguardando_doc_para_pagamento' ? permissions.can_change_docs && (
                <Link
                  className={shortclass.button.primary}
                  to={`/documentos-para-pagamento/${order.id}`}
                >Enviar Docs. para Pagamento</Link>
              ) : order.stageSlug === 'aguardando_validacao_do_financeiro' ? (
                <>
                  {permissions.can_approve_docs && (
                    <button
                      type="button"
                      className={shortclass.button.primary}
                      onClick={() => confirmApproval(true, true)}
                    >Aprovar Documentos</button>
                  )}
                  {permissions.can_reprove_docs && (
                    <button
                      type="button"
                      className={`
                        ${shortclass.button.normal}
                        bg-gray-50 border-red-700 text-red-700 
                        focus:ring-red-500 hover:bg-red-700
                        hover:text-gray-50
                      `}
                      onClick={() => confirmApproval(false, true)}
                    >Reprovar Documentos</button>
                  )}
                </>
              ) : permissions.can_confirm_bank_register ? (
                <button
                  type="button"
                  className={shortclass.button.primary}
                  onClick={handleConfirmBankRegistration}
                >Confirma Registro no Banco</button>
              ) : permissions.can_confirm_payment && (
                <button
                  type="button"
                  className={shortclass.button.primary}
                  onClick={handleConfirmPayment}
                >Confirma Pagamento</button>
              )}

              {permissions.can_upload_form && !!file && (
                <button
                  type="button"
                  className={shortclass.button.primary}
                  onClick={handleUploadForm}
                >Enviar Formulário</button>
              )}
            </div>
          </>
        )}
        {(!order || loading) && <Loading className={`
          absolute -top-16 -left-2 -right-2 h-[calc(100%+5rem)] w-[calc(100%+1rem)]
          bg-semi-transparent
        `} />}
      </div>
      <Modal
        isOpen={modalPage !== null}
        setIsOpen={() => setModalPage(null)}

        options={
          {
            title: `Confirmar Reprovação${modalPage === 'reproval-docs' ? ' de Documentos' : ''}`,
            cancelButton: true,
            cancelButtonText: 'Não',
            actionButton: {
              onClick: () => {
                let el = document.getElementById('confirm-reproval-reason') as HTMLInputElement;
                if (!el) {
                  toast.error('Não foi possível ler o motivo de reprovação');
                  return;
                }
                if (!el.value || el.value.length === 0) {
                  el.setCustomValidity('Preencha o motivo da reprovação');
                  setTimeout(() => el.setCustomValidity(''), 3000);

                  el.focus();
                  return;
                }

                if (modalPage === 'reproval-docs') {
                  handleApproveDocs(false, el.value);
                }
                else if (modalPage === 'reproval') {
                  handleApprove(false, el.value);
                }
                setModalPage(null)
              },
              theme: 'primary',
              text: 'Sim'
            },

          }
        }
      >
        <p className="text-gray-700 mt-4 mb-2">Deseja reprovar essa ordem?</p>

        <Textarea
          id="confirm-reproval-reason"
          className="invalid:bg-red-50 invalid:border-red-500 invalid:text-red-900 invalid:placeholder-red-700 invalid:focus:ring-red-500 invalid:focus:border-red-500 invalid:dark:text-red-500 invalid:dark:placeholder-red-500 invalid:dark:border-red-500" 
          placeholder="Digite o motivo da reprovação...">

        </Textarea>
      </Modal>
    </SlideOver>
  );
}