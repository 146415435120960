import styles from './Loading.module.scss';
import { Spinner } from 'flowbite-react';

interface LoadingProps {
  className?: string
}

export const Loading = ({ className } : LoadingProps) => (
  <div className={`${styles.loader__container} ${className ?? ''}`}>
    <Spinner
      aria-label="Carregando"
      size="xl"
    />
  </div>
)