
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Wrapper.module.scss';

import { useAuth } from '../../contexts/AuthContext';
import { useNotify } from '../../contexts/NotifyContext';
import { Loading } from '../Loading';
import { Client, PossiblePermissions, StatusType } from '../../types';
import { Textarea } from 'flowbite-react';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import { NewRequest } from '../Order/NewRequest';
import { MenuAside } from './MenuAside';
import { MenuAdminAside } from './MenuAdminAside';
import { HeaderAside } from './HeaderAside';

export interface FilterProps{
  status: {
    statusList: StatusType[] | undefined,
    filterStatus: string[] | undefined,
    setFilterStatus: (param: any) => void
  },
  requester: {
    filterRequester: string,
    setFilterRequester: (param: string) => void
  },
  date: {
    filterDate: DateValueType,
    setFilterDate: (date: DateValueType) => void
  },
  po: {
    filterNumberPO: string,
    setFilterNumberPO: (param: string) => void
  },
  onPageStart?: () => void
}
export type DisabledType = Array<'menu' | 'office' | 'help' | 'profile' | 'menu-aside'>;
interface WrapperProps{
  module_name?: string,
  options?: {
    disabled?: DisabledType
  },
  children?: ReactNode,
  onDeleteMultiple?: () => Promise<void>,
  filter?: FilterProps,
  setOrders?: (order: any[] | any) => void,
  isAdmin?: boolean
}
export interface ClientProps extends Client{
  active?: boolean
}
export function Wrapper({
  module_name,
  options,
  children,
  onDeleteMultiple,
  filter,
  setOrders,
  isAdmin = false
} : WrapperProps){
  const { toast, showMessage, onCloseModal } = useNotify();
  const { user, signOut, changeClient } = useAuth();
  const navigate = useNavigate();

  const [isOpenNewRequest, setIsOpenNewRequest] = useState(false);
  const [disableds, setDisableds] = useState(options?.disabled ?? []);
  const [clients, setClients] = useState<ClientProps[]>([]);

  useEffect(() => {
    if(!user) return;
    
    if(user.clients) setClients([
      ...user.clients.map(client => {
        return {
          ...client,
          active: client.id === user.current_client
        }
      })
    ]);
  },[user]);
  useEffect(() => {
    if(options && options.disabled && options.disabled !== disableds) setDisableds(options.disabled);
  }, [options])

  async function handleChangeClient(client_id: string, client_name: string){
    if(!user) return;
    toast.promise(changeClient(client_id, client_name, user.token), {
      'pending': 'Alterando empresa',
      'error': 'Houve um erro ao alterar a empresa',
      'success': 'Empresa alterada com sucesso'
    });
  }
  async function handleLogout(){
    let data = await toast.promise(signOut(),{
      pending: 'Desconectando',
      success: 'Logout efetuado',
      error: 'Houve um erro ao tentar sair da sua conta'
    });
    
    if(data.result){
      navigate(`/login`)
      return;
    }
  }
  function handleHelp(){
    showMessage((
      <div>
        <p className="text-sm text-gray-600 mb-3">
          Envie um email para nossa equipe de suporte que logo entraremos em contato.
        </p>
        <Textarea
          placeholder="Digite sua mensagem"
          required={true}
          className="mb-3"
          rows={4}
          id="modal-help-textarea"
        />
        <p className="text-sm text-gray-600">
          Para nos ajudar a resolver:<br/>
          - insira o link da pagina onde o problema foi identificado<br/>
          - informe o numero da PO e seu usuário<br/>
          - descreva de forma simples e objetiva o problema encontrado
        </p>
      </div>
    ), {
      title: 'Precisa de ajuda?',
      actionButton: {
        theme: 'primary',
        text: 'Enviar',
        autoClose: false,
        onClick: () => {
          let el = document.getElementById('modal-help-textarea') as HTMLTextAreaElement;
          let body = '';

          if(el?.value){
            body = encodeURIComponent(el.value);
            el.value = '';
          }
          
          window.location.href = `mailto:suportesistemas@ivrim.com.br?subject=${encodeURIComponent('Suporte Portal')}&body=${body}`;
          onCloseModal();
        }
      }
    });
  }

  if(!user) return <Loading/>;
  return (
    <div className={`${styles.container} max-w-7xl mx-auto w-full`}>
      <HeaderAside
        user={user}
        module_name={module_name}
        disableds={disableds}
        clients={clients}
        handleChangeClient={handleChangeClient}
        handleHelp={handleHelp}
        handleLogout={handleLogout}
      />
      <div className={`
        flex flex-col sm:flex-row gap-4
        w-full p-4 m-auto
      `} style={{ minHeight: 'calc(100vh - 12rem)', maxWidth: 'calc(100vw - 2rem)'}}>
        {isAdmin ? <MenuAdminAside/> : !disableds.includes('menu-aside') ? <MenuAside
          onDeleteMultiple={onDeleteMultiple}
          onOpenNewRequest={() => setIsOpenNewRequest(true)}
          filter={filter}
          disabled={!user.permitions_slug?.includes(PossiblePermissions.CONTAS_A_PAGAR)}
        />:<></>}
        { children }
        {!isAdmin && user.permitions_slug?.includes(PossiblePermissions.CONTAS_A_PAGAR) && (
          <NewRequest
            isOpen={isOpenNewRequest}
            setIsOpen={setIsOpenNewRequest}
            setOrders={setOrders}
          />
        )}
      </div>
      <footer className={`${styles.footer} bg-gradient-primary rounded-lg`}>
        <span style={{ fontSize: '.8rem', opacity: '.8' }}>
          IVRIM @ {(new Date()).getFullYear()} - All rigthts reserved</span>
      </footer>
    </div>
  );
}