import { Card, Label, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";

import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";

import { Loading } from "../../components/Loading";
import { Wrapper } from "../../components/Wrapper";
import { ChevronDownIcon, MoreVerticalIcon } from "../../components/SvgIcons";
import { Dropdown } from "../../components/utils/Dropdown";
import SlideOver from "../../components/utils/SlideOver";
import Select from "../../components/utils/Select";

import { Client, User, UserCategory } from "../../types";

import { shortclass } from "../../styles/styles";

import logo from '../../assets/default-client-logo.png';

import { createUser, searchUserByEmail } from "../../services/user";
import { getClient, removeUserOfClient } from "../../services/client";
import { changeUserCategory, getUserCategories } from "../../services/userCategory";

export const AdminUsers = () => {
  const { toast } = useNotify(); 
  const { user } = useAuth();

  const [client, setClient] = useState<Client>();
  const [users, setUsers] = useState<User[]>([]);
  const [isOpenFormCreateUser, setIsOpenFormCreateUser] = useState(false);

  const [userCategories, setUserCategories] = useState<UserCategory[]>([]);

  useEffect(() => {
    if(!user) return;
    loadClient();
    loadUserCategories();
  },[user]);
  useEffect(() => {
    if(!client && users.length > 0){
      setUsers([])
      return;
    }

    setUsers(() => client?.users ?? []);
  },[client])
  
  async function loadClient(){
    if(!user || !user.current_client) return;

    const client = await getClient(user.token);
    if(client) setClient(client);
  }
  async function loadUserCategories(){
    if(!user) return;

    const response = await getUserCategories(user.token);
    setUserCategories(response)
  }
  async function handleChangeUserCategory(userId: string, categoryId: string){
    if(!user) return;

    const response = await changeUserCategory(userId, categoryId, user.token);
    
    if(response.result && response.data){
      setUsers((prevState) => {        
        return prevState.map((userOfClient) => {
          if(userOfClient.id === userId) userOfClient.userCategory = response.data;
          return userOfClient;
        });
      });

      toast.success('Categoria do usuário alterada');
    }
    else toast.error(response.response);
  }
  async function handleRemoveUserOfClient(userId: string){
    if(!user) return;
    try{
      await removeUserOfClient(userId, user.token);
      setUsers((prevState) => {
        if(!prevState) return prevState;

        prevState = prevState?.filter((userOfClient) => userOfClient.id !== userId);
        return prevState;
      });
      toast.success('Usuário removido da empresa com sucesso');
    }catch(e){
      console.error(e);
      toast.error('Houve um erro inesperado ao tentar remover o usuário da empresa');
    }
  }
  async function handleAddNewUser(user: User, permission_id: string){
    setUsers((prevState) => [
      ...prevState,
      user
    ]);

    handleChangeUserCategory(user.id, permission_id);
  }
  function handleOpenNewUser(){
    setIsOpenFormCreateUser(true);

    let el = document.getElementById('new-user-name') as HTMLInputElement;
    if(el) el.focus();
  }
  return (
    <Wrapper 
      module_name="Painel Adm"
      isAdmin
    >
      {(!user || !client) ? <Loading className="h-[calc(100vh-14.5rem)] rounded-xl"/> : (
        <div className="w-full">
          <Card className="mb-6">
            <div className="flex items-center gap-4">
              <img
                className="h-14 w-14 rounded-full shadow-lg object-cover"
                alt={client.nome_fantasia}
                src={client.picture ?? logo}
                onError={(e) => {
                  let img = e.target as HTMLImageElement;
                  img.src = logo;
                }}
              />
              <div>
                <h5 className="mb-0 text-xl font-medium text-gray-900 dark:text-white">
                  {client.nome_fantasia}
                </h5>
              </div>
            </div>
          </Card>
          <div className="flex flex-col lg:flex-row gap-4 items-start">
            <div className={`
              flex rounded-lg border border-gray-200 bg-white 
              shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col gap-2
              mb-6 w-full lg:w-[28rem] min-h-[15rem] py-6
            `}>
              <div className="flex items-center justify-between gap-2 px-6">
                <h5 className="text-lg font-medium text-gray-900 dark:text-white">Usuários</h5>
                <button
                  type="button"
                  className={`
                    py-1 px-2
                    flex justify-center 
                    rounded-md border border-transparent 
                    text-xs font-semibold 
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                  text-white focus:ring-primary-500 bg-primary-600 hover:bg-primary-700
                  `}
                  onClick={handleOpenNewUser}
                >Adicionar</button>
              </div>
              <div className="overflow-y-auto max-h-96 relative">
                {users && users?.map((userOfClient) => {
                  if(userOfClient.id === user.id) return null;
                  return (
                    <div className="border-b last:border-b-0 hover:bg-gray-100 rounded-lg cursor-pointer px-2 mx-4" key={userOfClient.id}>
                      <div className="flex items-center py-2">
                        <img
                          alt={userOfClient.name}
                          className="rounded-full object-cover w-8 h-8"
                          src={userOfClient.picture}
                        />
                        <div className="ml-3 flex-1 flex justify-between items-center">
                          <div className="flex flex-col items-start">
                            <span className="whitespace-nowrap font-medium">{userOfClient.name}</span>
                            <span className="text-xs text-gray-600">{userOfClient.email}</span>
                            {userOfClient.userCategory && (
                              <span className="rounded bg-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500 dark:bg-gray-700 dark:text-gray-400">
                                {userOfClient.userCategory.name}
                              </span>
                            )}
                          </div>
                          <Dropdown
                            trigger={<MoreVerticalIcon color="rgb(55 65 81 / var(--tw-text-opacity))"/>}
                            classNames={{ wrapper: "block mt-2 text-left" }}
                            orientation={null}
                            styles={{ list: { transform: 'translateX(-12rem)' } }}
                          >    
                            <div>
                              <strong className="text-xs">Alterar categoria do usuário</strong>
                              {userCategories.map((category) => (
                                <button
                                  type="button"
                                  className={`${shortclass.dropdownItem} text-xs uppercase`}
                                  key={category.id}
                                  onClick={() => handleChangeUserCategory(userOfClient.id, category.id)}
                                  disabled={userOfClient.userCategory?.id ===  category.id}
                                >{'> '} {category.name ?? category.slug} </button>
                              ))}
                            </div>
                            <hr/>
                            <button
                              type="button"
                              className={shortclass.dropdownItem}
                              onClick={() => handleRemoveUserOfClient(userOfClient.id)}
                            >Remover da Empresa</button>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={`
              flex rounded-lg border border-gray-200 bg-white 
              shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col gap-2
              mb-6 flex-1 w-full min-h-[15rem] py-6
            `}>
              <div className="px-6">
                <h5 className="text-lg font-medium text-gray-900 dark:text-white">Categorias de Usuário</h5>
              </div>
              <div className="flex-1">
                {userCategories.map((category) => (
                  <div
                    className="border-b last:border-b-0 hover:bg-gray-100 rounded-lg cursor-pointer p-2 mx-4" 
                    key={category.id}
                  >
                    <div className="group">
                      <div
                        className="flex items-center justify-between gap-2"
                        onClick={(e) => {
                          let el = e.target as HTMLDivElement;
                          if(!el) return;
                          let target = null;
                          if(el.nodeName === 'DIV') target = el.parentElement;
                          else target = el.parentElement?.parentElement;
                          
                          target?.classList.toggle('expanded');
                        }}
                      >
                        <span className="uppercase text-sm text-gray-800 whitespace-nowrap font-medium">{category.name ?? category.slug}</span>
                        {category.permitions.length > 0 && <ChevronDownIcon className="group-[.expanded]:rotate-180"/>}
                      </div>
                      {category.permitions.length > 0 && (
                        <div className="group-[.expanded]:flex hidden flex-col p-2 gap-2">
                          {category.permitions.map((permition) => (
                            <span
                              className="text-gray-700 text-sm"
                              key={permition.id}
                            >{permition.name}</span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <FormCreateUser
            isOpen={isOpenFormCreateUser}
            onClose={() => setIsOpenFormCreateUser(false)}
            userCategories={userCategories}
            toast={toast}
            user={user}
            onAdded={handleAddNewUser}
          />
        </div>
      )}
    </Wrapper>
  );
}
interface FormCreateUserType{
  isOpen: boolean,
  onClose: () => void,
  onAdded: (user: User, permission_id: string) => void,
  userCategories: UserCategory[],
  toast: any,
  user: User
}
const FormCreateUser = ({ isOpen, onClose, onAdded, userCategories, toast, user }: FormCreateUserType) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userFinded, setUserFinded] = useState<User | null>(null);
  const [selectedPermission, setSelectedPermission] = useState<string>();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();
    // #region VALIDATE
    if(!user) return;
    if(!email){
      toast.error('O email é obrigatório');
      return;
    }
    if(userFinded && userFinded.email !== email){
      toast.error('O email do usuário não corresponde ao usuário selecionado');
      handleFindUserByEmail();
      return;
    }
    if(!userFinded && (
      !password || !name
    )){
      toast.error('Nome e a senha são obrigatórios para criação de usuário');
      return;
    }
    if(!selectedPermission){
      toast.error('Selecione a permissão do usuário na sua empresa');
      return;
    }
    // #endregion VALIDATE

    setLoading(true);
    if(userFinded){
      onAdded(userFinded, selectedPermission);
      setLoading(false);
    }
    else{
      const data = {
        email,
        password,
        name
      };
      const res = await createUser(data, user.token);
     
      setLoading(false);

      if(res.result && res.data){
        toast.success(res.response);
        onAdded(res.data!, selectedPermission);
      }
      else{
        toast.error(res.response);
        return;
      }
    }
    
    handleClose();
  }
  async function handleFindUserByEmail(){
    if(!user) return;
    if(!email || !/^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email)) return;
    if(userFinded && userFinded.email === email) return;

    setLoading(true);
    const res = await searchUserByEmail(email, user.token);
    setLoading(false);

    if(res.result && res.data){
      toast.success(res.response);
      setUserFinded(res.data);
      return;
    }
    else setUserFinded(null);
  }
  function handleClose(){
    setName('');
    setEmail('');
    setPassword('');
    setUserFinded(null);
    setSelectedPermission('');

    onClose();
  }
  return (
    <SlideOver
      title="Adicionar Usuário"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className="relative h-full">
        <form
          className="h-full flex flex-col justify-between"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="mb-5">
            <div className="mb-2">
              <div className="mb-2 block">
                <Label
                  htmlFor="new-user-email"
                  value="Email"
                />
              </div>
              <TextInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleFindUserByEmail}
                id="new-user-email"
                type="email"
                placeholder="Digite o email do usuário"
                required
                autoComplete="new-password"
              />
              <span className="text-xs text-gray-600 leading-4 mt-1 ml-1 block">Digite um novo email para cadastrar um novo usuário, ou um email existente para adicioná-lo a sua empresa</span>
            </div>
            {!userFinded && (
              <>
                <div className="mb-4">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="new-user-name"
                      value="Nome"
                    />
                  </div>                  
                  <div className="flex flex-col">
                    <div className="relative w-full">
                      <input
                        className={`
                          block w-full
                          border bg-gray-50 border-gray-300 text-gray-900 
                          focus:border-blue-500 focus:ring-blue-500 
                          dark:border-gray-600 dark:bg-gray-700 dark:text-white 
                          dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500
                          rounded-lg p-2.5 text-sm
                          read-only:bg-gray-200
                        `}
                        id="new-user-name"
                        type="text"
                        placeholder="Digite o nome do usuário"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        autoComplete="new-password"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="new-user-password"
                      value="Senha"
                    />
                  </div>
                  <TextInput
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="new-user-password"
                    type="password"
                    placeholder="Digite a senha do usuário"
                    required
                    autoComplete="new-password"
                  />
                </div>
              </>
            )}
            <div className="mb-4">
              <div className="mb-2 block">
                <Label
                  htmlFor="new-user-permission"
                  value="Permissão"
                />
              </div>
              <Select
                selectedRaw={<span>Permissão do Usuário</span>}
                onChange={(permission) => {
                  if(!permission?.id) setSelectedPermission(undefined);
                  else if(typeof permission.id === 'string')  setSelectedPermission(permission.id);
                }}
                options={[...userCategories.map((category) => {
                  return {
                    id: category.id,
                    html: <span>{category.name}</span>
                  }
                })]}
              />
            </div>
          </div>
          <button
            type="submit"
            className={`${shortclass.button.primary} w-full mt-6`}
          >Adicionar</button>
        </form>
        {loading && <Loading className={`
          absolute -top-16 -left-2 -right-2 h-[calc(100%+5rem)] w-[calc(100%+1rem)]
          bg-semi-transparent
        `}/>}
      </div>
    </SlideOver>
  );
}