import { Card, FileInput, Label } from "flowbite-react";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { CloseIcon, DeleteIcon, FileIcon, PlusIcon } from "../../components/SvgIcons";
import { Wrapper } from "../../components/Wrapper"
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";
import { deleteModel, getModels, uploadModel } from "../../services/model";
import { shortclass } from "../../styles/styles";
import { DocumentTemplateType, PossiblePermissions } from "../../types";

interface NewModelType{
  name: string,
  file: File | null
}
interface AgroupedTemplates{
  grouped_name: string | null,
  data: DocumentTemplateType[]
}
export const DocumentTemplate = () => {
  const { toast, showMessage } = useNotify();
  const { user } = useAuth();
  
  const [groupTemplates,setGroupTemplates] = useState<AgroupedTemplates[]>([]);
  const [newModels, setNewModels] = useState<NewModelType[]>([]);
  const [canUploadModels, setCanUploadModels] = useState(false);
  const [showFormNewModel, setShowFormNewModel] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!user) return;

    setCanUploadModels(
      !!(user.permitions_slug?.includes(PossiblePermissions.ADMIN))
    );

    loadTemplates();
  },[user]);

  async function loadTemplates(){
    if(!user) return;
    
    const models = await getModels(user.token);
    const handleNumberTwoChars = (n: number) => (String(n).padStart(2,'0'));
    
    let parsedTemplates = models.map(model => {
      try{
        let date = new Date(model.updated_at);
        let parsedDate = `
          ${handleNumberTwoChars(date.getDate())}/${handleNumberTwoChars(date.getMonth() + 1)}/${date.getFullYear()} ${handleNumberTwoChars(date.getHours())}:${handleNumberTwoChars(date.getMinutes())}
        `
        model.updated_at = parsedDate;
      }
      catch(e){ console.error(e); }

      return model;
    }).sort((a, b) => {
      if(a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
      if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
      return 0;
    });

    let agrouped : AgroupedTemplates[] = [];
    parsedTemplates.forEach(temp => {
      let arrPrefix = temp.name.split('_');
      let prefix : string | null = null;
      if(arrPrefix.length > 1) prefix = arrPrefix[0];

      let agroupedFinded = prefix ? agrouped.find(g => g.grouped_name === prefix) : null;
      if(agroupedFinded) agroupedFinded.data.push(temp);
      else agrouped.push({
        grouped_name: prefix,
        data: [temp]
      })
    });

    setGroupTemplates(agrouped);
  }
  function handleChangeFile(target: any){
    if(!target.files) return;

    const handleAppendFile = (prevState: NewModelType[]) : NewModelType[] => {
      let file = target.files[0];
      if(!file) return prevState;
      return [...prevState, {
        name: file.name,
        file
      }];
    }

    setNewModels(handleAppendFile);
  }
  function handleRemoveFile(index: number){
    const handleRemove = (prevState: NewModelType[]) : NewModelType[] => {
      if(prevState.length <= index){
        toast.error('Documento não encontrado');
        return prevState;
      }

      return prevState.filter((_, i) => i !== index);
    }
    
    setNewModels(handleRemove);
  }
  async function handleSubmitModels(){
    if(!user || loading) return;

    const filteredModels = newModels.filter((nModel) => !!nModel.file);
    if(filteredModels.length === 0){
      toast.warning('Adicione pelo menos um arquivo para fazer upload');
      return;
    }

    setLoading(true);
    const data = new FormData();
    filteredModels.forEach((nModel) => data.append('models', nModel.file!));
    const res = await uploadModel(data, user.token);
    setLoading(false);

    if(!res.result){
      toast.error(res.response);
      return;
    }
    toast.success(res.response);

    setNewModels([]);
    loadTemplates();
  }
  async function handleDeleteModel(src: string){
    const callback =  async () => {
      if(!user || loading) return;
      
      setLoading(true);
      const res = await deleteModel(src, user.token);
      setLoading(false);
      
      if(!res.result){
        toast.error(res.response);
        return;
      }
      toast.success(res.response);
  
      setGroupTemplates((prevState) => prevState.map(group => {
        group.data = group.data.filter(
          (model) => model.src !== src
        );
        return group;
      }).filter(group => group.data.length > 0));
    }

    showMessage((
      <p className="text-sm text-gray-700">
        Tem certeza que deseja excluir este modelo?
      </p>
    ), {
      title: 'Confirmar Exclusão',
      actionButton: {
        onClick: callback,
        theme: 'primary',
        text: 'Sim'
      },
      cancelButtonText: 'Não'
    })
  }
  
  return (
    <Wrapper
      module_name="Contas a Pagar"
    >
      <div className="w-full mb-4 relative">
        <div className="flex gap-2 mb-3">
          <h2 className="text-2xl">Modelos de Documentos</h2>
          {canUploadModels && (
            <button type="button" className={`
              text-gray-600 outline-none
              px-4 py-2 border rounded-lg inline-block
              focus:outline-none focus:ring-2 focus:ring-offset-2
              focus:ring-gray-200 hover:bg-gray-100 hover:border-2
            `} onClick={() => setShowFormNewModel(!showFormNewModel)}><PlusIcon/></button>
          )}
        </div>
        {canUploadModels && showFormNewModel && (
          <Card className="max-w-lg mb-5 mx-auto lg:mx-0">
            <div>
              <div className="mb-2">  
                <div className="mb-3">
                  <span className="font-medium">Adicionar Modelo</span>
                </div>
                <FileInput
                  id={`upload-form-model`}
                  defaultValue={""}
                  className="border rounded-lg"
                  onChange={(e) => handleChangeFile(e.target)}
                />
                <ul className="mt-4 max-w-[calc(100vw-8.50rem)]">
                  {newModels.map((nModel, i) => (
                    <li className="p-2 flex flex-col xs:flex-row justify-between items-center gap-2 border-b last:border-b-0" key={nModel.name + i}>
                      <span className="text-sm text-gray-800 text-ellipsis overflow-hidden whitespace-nowrap max-w-full">{nModel.name}</span>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className={`
                            text-gray-600 outline-none
                            px-4 py-2 border rounded-lg inline-block
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-gray-200 hover:bg-gray-100 hover:border-2
                          `}
                          onClick={() => handleRemoveFile(i)}
                        ><DeleteIcon width="20" height="20"/></button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {newModels.length > 0 && (
                <button
                  type="button"
                  className={`${shortclass.button.primary} w-full mt-6`}
                  onClick={handleSubmitModels}
                >Salvar</button>
              )}
            </div>
          </Card>
        )}
        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-4">
          {groupTemplates.length > 0 ? groupTemplates.map((template) => {
            if(template.data.length > 1 && template.grouped_name) return (
              <div className={`
                bg-gray-100 shadow border rounded-lg py-6 px-5 group 
                flex flex-col justify-between
              `} id={`group-template-${template.grouped_name}`}>
                <h2 className="text-md uppercase font-semibold text-gray-800 block mb-2">{template.grouped_name}</h2>
                <div
                  className={`
                    flex items-center gap-2 
                    group-[.expanded]:hidden bg-gray-50 hover:bg-gray-100 p-2 shadow 
                    border rounded-lg text-gray-800 cursor-pointer
                  `}
                  onClick={() => {
                    let el = document.getElementById(`group-template-${template.grouped_name}`) as HTMLDivElement;
                    if(el) el.classList.add('expanded');
                  }}
                >
                  <FileIcon/>
                  <span className="text-sm text-gray-600">{template.data.length} Arquivos</span>
                </div>
                <div className={`
                  hidden group-[.expanded]:flex
                  fixed h-screen w-screen inset-0 bg-[#0006]
                  py-6 z-2 overlay
                `} onClick={(e) => {
                  let target = e.target as HTMLDivElement;
                  if(target && target.classList.contains('overlay')){
                    let el = document.getElementById(`group-template-${template.grouped_name}`) as HTMLDivElement;
                    if(el) el.classList.remove('expanded');
                  }
                }}>
                  <div className="bg-white p-6 shadow rounded-lg border max-w-3xl flex-1 m-auto">
                    <div className="text-gray-700 flex gap-2 justify-between mb-4">
                      <h2 className="text-md uppercase font-semibold text-gray-800 block mb-2">{template.grouped_name}</h2>
                      <button type="button" className="mb-0 p-0 cursor-pointer" onClick={() => {
                        let el = document.getElementById(`group-template-${template.grouped_name}`) as HTMLDivElement;
                        if(el) el.classList.remove('expanded');
                      }}>
                        <CloseIcon width="14"/>
                      </button>
                    </div>
                    <div className="grid grid-cols-1 xs:grid-cols-2 grid-flow-row gap-4 max-h-full overflow-y-auto">
                      {template.data.map((template, i) => (
                        <CardTemplate
                          template={template}
                          key={`${template.src}-${i}`}
                          onDelete={handleDeleteModel}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            );

            return (
              <>
                {template.data.map((template, i) => (
                  <CardTemplate
                    template={template}
                    key={`${template.src}-${i}`}
                    onDelete={handleDeleteModel}
                  />
                ))}
              </>
            );
          }) : (
            <p className="text-center col-span-3 py-6 text-lg text-gray-600 leading-6">Não há nenhum modelo<br/> de documento cadastrado</p>
          )}
        </div>
        {loading && <Loading className={`
          absolute top-0 left-0 right-0 h-full w-full
          bg-semi-transparent
        `}/>}
      </div>
    </Wrapper>
  );
}

const CardTemplate = ({ template, onDelete }: {
  template: DocumentTemplateType,
  onDelete: (src: string) => void
}) => (
  <Card>
    <div className="flex flex-col gap-4 justify-between flex-1">
      <div className="flex flex-col gap-0.5">
        <span className="font-medium">{template.name}</span>
        <span className="text-xs text-gray-400">{template.updated_at}</span>
      </div>
      <div className="flex gap-2">
        <a
          target="_blank"
          rel="noreferrer"
          href={`${process.env.REACT_APP_BASE_URL}${template.src}`}
          className={`${shortclass.button.primary} flex-1`}
        >Baixar</a>
        <button
          type="button"
          className={`
            text-gray-600 outline-none
            px-4 py-2 border rounded-lg inline-block
            focus:outline-none focus:ring-2 focus:ring-offset-2
            focus:ring-gray-200 hover:bg-gray-100 hover:border-2
          `}
          onClick={() => onDelete(template.src)}
        ><DeleteIcon width="20" height="20"/></button>
      </div>
    </div>
  </Card>
)