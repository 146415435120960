import { UserCategory } from "../types";
import { api, headerBearer } from "./api"

export const getUserCategories = async (token: string) : Promise<UserCategory[]> => {
  const { data } = await api.get('/user-categories', headerBearer(token));
  return data;
}
export const changeUserCategory = async (user_id: string, category_id: string, token: string) : Promise<{
  result: boolean,
  response: string,
  data?: UserCategory
}> => {
  try{
    const { data } = await api.post(`/user-category-user/${user_id}/${category_id}`,{},  headerBearer(token));
    return {
      result: true,
      response: 'Permissão alterada com sucesso',
      data
    };
  }catch(e: any){
    console.error(e);
    let errorMessage = 'Houve um erro inesperado ao alterar a permissão';
    if(e?.response?.data){
      switch(e.response.data){
        case "Cannot read properties of undefined (reading 'permitionSlugs')":
          errorMessage = 'Sem permissão para executar essa função';
          break;
        default: errorMessage = e.response.data; break;
      }
    }
    return {
      result: false,
      response: errorMessage,
    };
  }
}