import { OrderFormattedType, OrderType, ResultAndResponse } from "../types";
import { api, headerBearer } from "./api";
import moment from 'moment';
import { clearCacheOrderHistoryOrderId } from "./orderHistory";

export const deleteOrder = async (order_id: string, token: string, po?: string) => {
  try{
    const response = await api.delete(`/order/${order_id}`, headerBearer(token))
  
    if(response.data === 'Ok') return {
      result: true,
      response: `PO${ po ? ' ' + po : ''} excluida com sucesso`
    }
    return  {
      result: false,
      response: response.data
    }
  }
  catch(e){
    console.error(e);
    return {
      result: false,
      response: 'Houve um erro inesperado ao tentar excluir a PO' + (
        po ? ' ' + po : ''
      )
    };
  }
  
}
export const requestPurchase = async (data: FormData, token:string) => {
  try{
    const response = await api.post('/purchase-order', data, headerBearer(token));
  
    return {
      result: true,
      response: !!response.data ? 'Solicitação cadastrada com sucesso':'Houve um erro nesta solicitação',
      data: response.data
    };
  }catch(e: any){
    let error_message = null;
    if(e.response.data.message){
      switch(e.response.data.message){
        case "This type is not compatible":
          error_message = "O tipo de arquivo selecionado não é compatível";
          break;
          
      }
    }
    return {
      result: false,
      response: error_message ?? 'Houve um erro inesperado',
      data: null
    }
  }
}
export const uploadFormPO = async (data: FormData, token: string) => {
  // data : { id: string, file: string }
  try{
    const response = await api.put('purchase-order-file', data, headerBearer(token));

    clearCacheOrderHistoryOrderId(data.get('id') as string);

    return {
      result: true,
      response: 'Formulário enviado com sucesso',
      data: response.data
    };
  }catch(e: any){
    let error_message = null;
    if(e.response.data.message){
      switch(e.response.data.message){
        case "This type is not compatible":
          error_message = "O tipo de arquivo selecionado não é compatível";
          break;
          
      }
    }
    return {
      result: false,
      response: error_message ?? 'Houve um erro inesperado',
      data: null
    }
  }
}
export const getOrder = async (order_id: string, token: string) => {
  const response = await api.get(`/order/${order_id}`, headerBearer(token));
  return response.data;
}
export interface OptionsFilterGetOrders{
  take?: number,
  excludedIds?: string[],
  filter_status?: string[],
  filter_user?: string,
  filter_num_po?: string,
  filter_date_start?: string,
  filter_date_end?: string
}
export const getOrders = async (token: string, data?: OptionsFilterGetOrders) : Promise<{
  response: OrderType[],
  count: number
}> => {
  const response = await api.post(`/solicitacoes${
    data?.take ? `?perPage=${data.take}` : '' 
  }`, data ?? {}, headerBearer(token));
  return response.data;
}
interface CacheDescriptionsType {
  token: string,
  data: ResponseGetDescriptions
}
const cacheDescriptions : CacheDescriptionsType[]= [];
interface ResponseGetDescriptions extends ResultAndResponse{ data?: string[] }
export const getDescriptions = async (token: string) : Promise<ResponseGetDescriptions> => {
  try{
    let findedCache = cacheDescriptions.find(cache => cache.token === token);
    if(findedCache && findedCache.data.result){
      console.log('[cache-descriptions]');
      return findedCache.data;
    }
  }catch(e){ console.error(e); }

  try{
    const { data } = await api.get<string[]>('/orders/descriptions', headerBearer(token));

    console.log('[request-descriptions]')
    const response = {
      result: true,
      response: 'Descrições carregadas',
      data
    };
    
    cacheDescriptions.push({ token, data: response });

    return response;
  }catch(e){
    console.error(e);

    return {
      result: false,
      response: 'Erro ao carregar descrições'
    };
  }
}
type RulesOperatorType = "equal" | "lt" | "gt" | "lte" | "gte";
interface RulesItemType {
  operation: RulesOperatorType,
  value: number 
}
export interface RulesType {
  warning: RulesItemType,
  now: RulesItemType,
  expired: RulesItemType
}
export const getOrderWithWarning = async (token: string) => {
  const response = await api.get('/orders-with-warning', headerBearer(token));
  return response.data as {
    warning: OrderType[],
    now: OrderType[],
    expired: OrderType[],
    rules: RulesType
  };
}

interface ResponseApprovaleOrderType {
  result: boolean,
  response: string,
  data: OrderType
}
export const approvalOrder = async (data: {
  orderId: string,
  isApproval: boolean,
  reprovalReason?: string
}, token: string) => {
  const response = await api.put<ResponseApprovaleOrderType>('/order-approval', data, headerBearer(token));

  clearCacheOrderHistoryOrderId(data.orderId);

  return response.data;
}
export const approvalOrderDocs = async (data: {
  orderId: string,
  isApproval: boolean,
  reprovalReason?: string
}, token: string) : Promise<ResponseApprovaleOrderType> => {
  const response = await api.put('/order-docs-approval', data, headerBearer(token));

  clearCacheOrderHistoryOrderId(data.orderId);

  return response.data;
}
export const lastStatusOrder = async (order_id: string, token: string): Promise<{
  result: boolean,
  response: string,
  data?: OrderType
}> => {
  try{
    const response = await api.put('/order-last-status', {
      order_id
    }, headerBearer(token));

    clearCacheOrderHistoryOrderId(order_id);

    return response.data;
  }catch(e: any){
    let error_message = null;
    if(e.response.data.message) error_message = e.response.data.message;
  
    return {
      result: false,
      response: error_message ?? 'Houve um erro inesperado'
    }
  }
}
export async function downloadZipOrder(order_id: string, order_slug: string, token: string){
  const res = await api.get(`/download/${order_id}`, {
    responseType:'blob',
    headers: {
      "Authorization": `Bearer ${token}`,
    }
  }).then((res) => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(res.data);
    link.href = url;
    link.download = `${order_slug}-documentos.zip`;
    link.click();
    URL.revokeObjectURL(res.data);
  })
  .catch((e) => console.error(e));;
  return res;
}
export function formatRows(orders: OrderType[]) : OrderFormattedType[]{
  const rows = orders.map((order) => {
    return{
      statusSlug: order.statusSlug,
      stageSlug: order.stageSlug,
      status: order.status.name,
      stage: order.stage.name,
      poId: order.poId,
      description: order.description,
      costCenter: order.costCenter.name,
      user: order.user.name,
      userEmail: order.user.email,
      userId: order.userId,
      date: moment(order.date).format('DD/MM/YYYY'),
      update: moment(order.updateDate).format('DD/MM/YYYY'),
      id: order.id,
      type: order.purchaseOrderType.type,
      typeSlug: order.purchaseOrderType.slug,
      src: order.src,
      remainingDays: order.remainingDays,
      documents: order.documents,
      deposito: order.deposito ?? undefined,
      data: order.data ?? null
    }
  }) as OrderFormattedType[];

  return rows;
}