import { User, ResultAndResponse, Permition, PossiblePermissions } from "../types";
import { api, headerBearer } from "./api";

export interface AuthenticateType{
  email: string,
  password: string,
  remember_me?: boolean
}

export const authenticate = async (data: AuthenticateType) : Promise<User> => {
  try {
    const response = await api.post('/auth', data, { headers:{'Content-Type': 'application/json'}});

    let permitions = handleFormatPermitionsSlug(response.data.permitions);

    return {
      ...response.data,
      permitions_slug: permitions
    } as User;
  } catch (er:any) {
    if(er?.response?.data?.message)
    throw new Error(er.response.data.message.slice(7));
    else
    throw new Error("Houve um erro inesperado");
  }
}
export async function logout() : Promise<ResultAndResponse>{
  /**
   * É necessário fazer o logout também no backend para que o token não seja mais válido
   */

  return new Promise((resolve) => {
    resolve({
      result: true,
      response: 'Logout efetuado com sucesso'
    });
  });
}
interface ChangeCurrentClientResponseType extends ResultAndResponse{
  data?: {
    token: string,
    permitions: Permition[],
    permitions_slug: PossiblePermissions[]
  }
}
export async function changeCurrentClient(client_id: string, token: string) : Promise<ChangeCurrentClientResponseType>{
  try{
    const { data } = await api.get(`/token/${client_id}`, headerBearer(token));

    let permitions_slug = handleFormatPermitionsSlug(data.permitions);

    return {
      result: true,
      response: 'Empresa alterada com sucesso',
      data: {
        ...data,
        permitions_slug
      }
    };
  }catch(e){
    console.error(e);
    return {
      result: false,
      response: 'Houve um erro ao alterar a empresa'
    };
  }
}

function handleFormatPermitionsSlug(permitions: undefined | any | any[]) : string[]{
  if(permitions &&
    Array.isArray(permitions) && 
    permitions.length > 0 && 
    typeof permitions[0] === 'string'
  ) return permitions;

  return [];
}