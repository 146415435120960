export interface ComprovanteType {
  name: string,
  id: string,
  src: string,
  orderid: string
}
export interface ContratoType {
  name: string,
  id: string,
  src: string,
  orderid: string
}
export interface BoletoType{
  name: string,
  id: string,
  src: string,
  orderid: string
}
export interface ResultAndResponse {
  result: boolean,
  response: string
}

export enum PossiblePermissions {
  APPROVAL = 'aprovacao',
  ADMIN =  'admin',
  FINANCIAL_APPROVAL = 'aprovacao-financeiro',
  EXCLUSION = 'exclusao',
  EDITION = 'edicao',
  CONTAS_A_PAGAR = 'cap',
  GESTAO = 'gestao'
}

export interface User{
  id: string;
  email: string,
  picture: string,
  active?: boolean,
  name: string,
  clients?: Client[],
  permitions?: Permition[],
  permitions_slug?: PossiblePermissions[],
  client_name?: string,
  token: string,
  current_client: string,
  userCategories?: UserCategory[],
  userCategory?: UserCategory
}
export interface UserCategory{
  id: string,
  slug: string,
  name: string,
  description: string,
  clientId: string,
  permitions: Permition[] 
}
export interface Client{
  id: string,
  cnpj: string,
  razao_social: string,
  nome_fantasia: string,
  picture: string,

  cep: string,
  logradouro: string,
  numero: string,
  bairro: string,
  cidade: string,
  estado: string,
  
  ddd: string,
  telefone: string,
  email: string,

  users?: User[]
}
export interface Permition{
  id: string,
  name: string,
  slug?: string,
  description?: string,
}
export interface Project{
  id: string,
  name: string,
  slug: string,
  clientId: string,
  is_active?: boolean
}
export interface StageType{
  id?: string,
  name: string,
  slug?: string,
  step?: number
}
export interface OrderType {
  id: string,
  clientId: string,
  costCenterId: string,
  purchaseOrderTypeSlug: string,
  description: string,
  statusSlug: string,
  totalAmount: number,
  userId: string,
  costCenter: CostCenterType,
  step: number,
  deposito: DepositoType,
  boletos: BoletoType[],
  comprovantes: ComprovanteType[],
  contratos: ContratoType[],
  chosenBudget: BudgetType,
  budgets: BudgetType[],
  date: string,
  updateDate: string,
  status: { name: "Em Aprovação" | "Reprovado" | "Aprovado" },
  stageSlug: string,
  stage: StageType,
  user: { name: string, picture: string, email: string },
  purchaseOrderType: PurchaseOrderTypeType
  poId: string,
  src: string,
  data: any,
  remainingDays?: number,
  documents?: PaymentDocType[]
}
export interface OrderFormattedType{
  status: string,
  stage: string,
  description: string,
  stageSlug: string,
  statusSlug: string,
  poId: string,
  costCenter: string,
  user: string,
  userId: string,
  userEmail: string,
  date: string,
  update: string,
  id: string,
  type: string,
  typeSlug: string,
  src: string,
  remainingDays?: number,
  documents?: PaymentDocType[],
  deposito?: DepositoType,
  data: any
}
export interface CostCenterType {
  clientId: string,
  id: string,
  location: string,
  name: string
}
export interface BudgetType {
  name: string,
  id: string,
  formaPagamento: string,
  valorTotal: number,
  src: string
}
export interface PurchaseOrderTypeType{
  id: string,
  type: string,
  slug: string
}
export interface StatusType{
  name: string;
  id: string;
  slug: string;
  step: number;
}
export interface SupplierType{
  name: string,
  email: string,
  contatos: string,
}
export interface PaymentDocType{
  type: string,
  name: string,
  src: string
}
export interface DepositoType{
  agencia: string
  banco: string
  cnpj: string
  contacorrente: string
}
export interface DocumentTemplateType{
  src: string,
  name: string,
  updated_at: string
}
export interface StorageKeyType{
  user: string
  token: string,
  costCenters: string,
  developers: string,
  version: string
}
export interface OrderHistoryType{
  id: string,
  order_id: string,
  user_id: string,
  client_id: string,
  status_slug: string,
  stage_slug: string,
  description: string,
  created_at: string,
  date: string,
  user: { email: string },
  order: { poId: string },
  status: { name: string },
  stage: { name: string },
}
export interface ManagementLinkType{
  user_id: string,
  name: string,
  email: string,
  picture: string,
  src: string | null
}