import { invalidTokenJWT } from "../contexts/AuthContext";
import { CostCenterType, ResultAndResponse } from "../types";
import { api, headerBearer } from "./api";
import { storageKeys } from "./cache";

interface DataCacheType{
  token: string,
  data: CostCenterType[]
}
interface CostCenterResponseType extends ResultAndResponse{
  data?: CostCenterType[]  
}
export const getCostCenter = async (token: string) : Promise<CostCenterResponseType> => {
  // #region HANDLE CACHE
  const storageKey = storageKeys.costCenters;
  let JSONCache = sessionStorage.getItem(storageKey);
  let costCenters : CostCenterType[] = [];
  let dataCache : DataCacheType[] = [];
  if(JSONCache){
    try{
      dataCache = JSON.parse(JSONCache);
      if(dataCache.length > 0){
        let findedCache = dataCache.find(cache => cache.token === token);
        if(findedCache){
          costCenters = findedCache.data;
        }
      }
    }catch(e){
      console.error(e);
      dataCache = [];
    }
  }
  if(costCenters.length > 0){
    console.log('[cache-cost-centers]');

    return {
      result: true,
      response: 'Centros de custos carregados',
      data: costCenters
    };
  }
  // #endregion HANDLE CACHE
  try{
    const { data } = await api.get('/cost-centers', headerBearer(token));
    console.log('[request-cost-centers]');

    if(!Array.isArray(data) && data.message){
      if(data.message === 'Invalid JWT token') invalidTokenJWT();
      return {
        result: false,
        response: 'Houve um erro ao carregar centro de custos'
      };
    }

    if(data.length > 0){
      dataCache.push({ token, data });
      sessionStorage.setItem(storageKey, JSON.stringify(dataCache));
    }
    
    return {
      result: true,
      response: 'Centros de custos carregados',
      data
    };
  }
  catch(e: any){
    if(e?.response?.data?.message === 'Invalid JWT token') invalidTokenJWT();
    return {
      result: false,
      response: 'Houve um erro ao carregar os centros de custos'
    };
  }
}