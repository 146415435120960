import { Wrapper } from "../../components/Wrapper";
import {
  Apagar,
  Areceber,
  ArrowBackIcon,
  CheckedIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  ChevronUpDownIcon,
  CloseIcon,
  Controladoria,
  DeleteIcon,
  DownloadAltIcon,
  DownloadIcon,
  EventoSolicitacao,
  FastTrack,
  IconPlusCircle,
  IconsubtractCircle,
  LeftArrow,
  LockIcon,
  MoreVerticalIcon,
  PlusIcon,
  RocketIcon,
  SearchIcon,
  ShoppingCart,
  Solicitacoes,
  SolicitarCompra,
  Tesouraria,
  UploadIcon,
  WarningIcon
} from "../../components/SvgIcons";
import { ReactNode } from "react";

const Box = ({ children } : { children: ReactNode }) => {
  return (
    <div className="shadow" style={{
      border: '1px solid #99a',
      borderRadius: '.4rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '.2rem',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '5rem',
      padding: '.4rem'
    }}>
      {children}
    </div>
  );
}
export const Icons = () => {
  const icons = [
    {node: <Areceber/>, name: 'Areceber'},
    {node: <Apagar/>, name: 'Apagar'},
    {node: <Tesouraria/>, name: 'Tesouraria'},
    {node: <Controladoria/>, name: 'Controladoria'},
    {node: <FastTrack/>, name: 'FastTrack'},
    {node: <Solicitacoes/>, name: 'Solicitacoes'},
    {node: <SolicitarCompra/>, name: 'SolicitarCompra'},
    {node: <EventoSolicitacao/>, name: 'EventoSolicitacao'},
    {node: <RocketIcon/>, name: 'RocketIcon'},
    {node: <ShoppingCart/>, name: 'ShoppingCart'},
    {node: <SearchIcon color="black"/>, name:'SearchIcon'},
    {node: <IconsubtractCircle/>, name: 'IconsubtractCircle'},
    {node: <IconPlusCircle/>, name: 'IconPlusCircle'},
    {node: <PlusIcon/>, name: 'PlusIcon'},
    {node: <CloseIcon/>, name: 'CloseIcon'},
    {node: <CheckedIcon/>, name: 'CheckedIcon'},
    {node: <UploadIcon color="black"/>, name: 'UploadIcon'},
    {node: <DownloadIcon/>, name: 'DownloadIcon'},
    {node: <DownloadAltIcon/>, name: 'DownloadAltIcon'},
    {node: <ArrowBackIcon/>, name: 'ArrowBackIcon'},
    {node: <LeftArrow/>, name: 'LeftArrow'},
    {node: <ChevronDownIcon/>, name: 'ChevronDownIcon'},
    {node: <ChevronsLeftIcon/>, name: 'ChevronsLeftIcon'},
    {node: <ChevronsRightIcon/>, name: 'ChevronsRightIcon'},
    {node: <ChevronLeftIcon/>, name: 'ChevronLeftIcon'},
    {node: <ChevronRightIcon/>, name: 'ChevronRightIcon'},
    {node: <ChevronUpDownIcon/>, name: 'ChevronUpDownIcon'},
    {node: <MoreVerticalIcon/>, name: 'MoreVerticalIcon'},
    {node: <DeleteIcon/>, name: 'DeleteIcon'},
    {node: <LockIcon/>, name: 'LockIcon'},
    {node: <LockIcon isFilled/>, name: 'LockIcon'},
    {node: <WarningIcon/>, name: 'WarningIcon'},
  ];

  return (
    <Wrapper>
      <div className={'w-full'} style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '.4rem',
        padding: '0 0 2rem'
      }}>
        {icons.map((icon,i) => (  
          <Box key={`${icon.name}-${i}`}>
            <>
              {icon.node}
              <span>{icon.name}</span>
            </>
          </Box>
        ))}
      </div>
    </Wrapper>
  )
}