import { invalidTokenJWT } from "../contexts/AuthContext";
import { Client } from "../types";
import { api, headerBearer } from "./api";

export const getClient = async (token: string) : Promise<Client | undefined>=> {
  try{
    const response = await api.get(`/client`, headerBearer(token));
    return response.data
  }
  catch(e: any){
    if(e?.response?.data?.message === 'Invalid JWT token') invalidTokenJWT();
    return undefined;
  }
}
export const removeUserOfClient = async (userId: string, token: string) : Promise<any> => {
  const response = await api.delete(`/remove-user-from-client/${userId}`, headerBearer(token));
  return response.data;
}