import { useEffect, useRef, useState } from "react";
import { FileInput, Label, Radio, Textarea, TextInput } from 'flowbite-react';

import { useAuth } from "../../contexts/AuthContext";
import SlideOver from '../utils/SlideOver';
import Select from '../utils/Select';
import { Loading } from "../Loading";
import { CostCenterType, OrderFormattedType, Project, SupplierType } from "../../types";
import { getProjects } from "../../services/project";
import { useNotify } from "../../contexts/NotifyContext";
import { shortclass } from "../../styles/styles";
import { ChevronDownIcon } from "../SvgIcons";
import { formatRows, getDescriptions, requestPurchase } from "../../services/order";
import { useNavigate } from "react-router-dom";
import { getCostCenter } from "../../services/costCenter";
import { getSuppliers } from "../../services/suppliers";

interface NewRequestProps{
  isOpen: boolean,
  setIsOpen: (param: boolean) => void,
  setOrders?: (order: any[] | any) => void
}
const types = [{
  type: 'Serviços',
  slug: 'servico'
},{
  type: 'Produto',
  slug: 'produto'
},{
  type: 'Geral e Adm',
  slug: 'geral_e_adm'
}];

export const NewRequest = ({
  isOpen,
  setIsOpen,
  setOrders
}: NewRequestProps) => {
  const { user } = useAuth();
  const { toast } = useNotify();
  const navigate = useNavigate();
  
  const providerInfoRef = useRef<HTMLDivElement>(null);

  const [costCenters, setCostCenters] = useState<CostCenterType[]>([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState<string | null>();
  const [description,setDescription] = useState<string>();
  const [savedDescriptions, setSavedDescriptions] = useState<string[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [project, setProject] = useState<string>();

  const [type, setType] = useState<string>();
  const [file, setFile] = useState<File | null>(null);

  const [suppliers, setSuppliers] = useState<SupplierType[]>();
  const [supplierName, setSupplierName] = useState<string>();
  const [supplierEmail, setSupplierEmail] = useState<string>();
  const [supplierContato, setSupplierContato] = useState<string>();
  const [oldSupplierContacts, setOldSupplierContacts] = useState<string[]>();
  const supplierContactRef = useRef<HTMLInputElement>(null);


  const [loading,setLoading] = useState(false);

  useEffect(() => {
    if(!user) return;

    (async () => {
      const responseCostCenter = await getCostCenter(user.token);
      setCostCenters(responseCostCenter.result ? responseCostCenter.data! : []);

      const responseProject = await getProjects(user.token);
      if(responseProject.result && responseProject.data
      ) setProjects(responseProject.data);
      else toast.error(responseProject.response);

      handleCompleteDescriptions();
    })()
  },[user]);
  useEffect(() => {
    if(!user || !isOpen) return;
    (async () => {
      const responseSupplier = await getSuppliers(user.token);
      setSuppliers(responseSupplier);
    })();
  },[user, isOpen]);
  useEffect(() => {
    let existentSupplier = suppliers?.find(s => s.email === supplierEmail);
    if(existentSupplier){
      setSupplierName(existentSupplier.name);
      setOldSupplierContacts(
        existentSupplier.contatos.split(',')
      );
      if(supplierContactRef.current) supplierContactRef.current.focus();
    }else setOldSupplierContacts(undefined);
  }, [supplierEmail]);

  async function handleCompleteDescriptions(){
    if(!user) return;

    const res = await getDescriptions(user.token);
    setSavedDescriptions(res.data ?? []);
  }
  function handleFile(e: any) {
    if (!e?.target?.files) {
      return;
    }
    setFile(e?.target?.files[0]);
  }
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();
    
    // #region VALIDATION
    if(!user || loading) return;

    if(!selectedCostCenter){
      toast.warning('Selecione o centro de custo');
      return;
    }
    if(!project){
      toast.warning('Selecione o projeto');
      return;
    }
    if(!description){
      toast.warning('Descrição da ordem');
      return;
    }
    if(!type){
      toast.warning('Selecione o tipo de solicitação (serviço, produto ou geral e adm)');
      return;
    }
    // #endregion VALIDATION
    
    const data = new FormData();
    
    data.append('costCenterId', selectedCostCenter);
    data.append('projectId', project);
    data.append('purchaseOrderTypeSlug', type);
    data.append('description', description);

    if(file) data.append('file', file);

    if(supplierName) data.append('supplierName', supplierName);
    if(supplierEmail) data.append('supplierEmail', supplierEmail);
    if(supplierContato) data.append('supplierUserName', supplierContato);

    setLoading(true);
    const response = await toast.promise(requestPurchase(data, user.token), {
      pending: 'Enviando',
      error: 'Houve um erro inesperado ao enviar solicitação'
    });
    setLoading(false);

    if(!response.result){
      toast.error(response.response);
      return;
    }

    toast.success(response.response);
    
    closeNewRequest();
  
    if(setOrders){
      setOrders((orders: OrderFormattedType[]) => {
        let excludedIds = orders.map(o => o.id) ?? [];
        if(excludedIds.includes(response.data.id)) return orders;
        
        return [
          ...formatRows([response.data]),
          ...orders,
        ];
      })
    }

    navigate(`/compras-e-contas-a-pagar?poId=${response.data.poId}`);
  }
  function closeNewRequest(){
    setSelectedCostCenter(undefined);
    setProject(undefined);
    setType(undefined);
    setFile(null);
    setSupplierName(undefined);
    setSupplierEmail(undefined);
    setSupplierContato(undefined);
    setIsOpen(false);
  }

  return (
    <SlideOver
      title="Nova Solicitação"
      isOpen={isOpen}
      onClose={closeNewRequest}
    >
      <div className="relative h-full">
        <form
          className="h-full flex flex-col justify-between"
          onSubmit={handleSubmit}
        >
          <div>
            <div className="mb-4">
              <Select
                label="Selecione o Centro de Custo"
                selectedRaw={<span>Centro de Custo</span>}
                onChange={(cost) => {
                  if(!cost?.id) setSelectedCostCenter(undefined);
                  else if(typeof cost.id === 'string')  setSelectedCostCenter(cost.id);
                }}
                options={[...(
                  costCenters ? costCenters?.map((constCenter) => {
                    return {
                      id: constCenter?.id,
                      html: <span>{constCenter?.name}</span>
                    }
                  }) ?? [] : []
                )]}
              />
            </div>
            <div className="mb-4">
              <Select
                label="Selecione o Projeto"
                selectedRaw={<span>Projetos</span>}
                onChange={(proj) => {
                  if(!proj?.id) setProject(undefined);
                  else if(typeof proj.id === 'string')  setProject(proj.id);
                }}
                options={[...(
                  projects.map((proj) => {
                    return {
                      id: proj.id,
                      html: <span>{proj.name}</span>
                    }
                  })
                )]}
                emptyRaw={<span className="py-2 pl-3 text-gray-600">Sua empresa não possui projetos</span>}
              />
            </div>
            <div className="mb-6 -mt-1">
              <div className="mb-1 block">
                <Label
                  htmlFor="new-request-description"
                  value="Descrição"
                  className="text-gray-700"
                />
              </div>
              <TextInput
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="new-request-description"
                type="text"
                placeholder="Digite a descrição da ordem"
                list="datalist-po-descriptions"
                required
              />
              {savedDescriptions && savedDescriptions.length > 0 && (
                <datalist id="datalist-po-descriptions">
                  {savedDescriptions.map((savedDescription) => (
                    <option value={savedDescription}/>
                  ))}
                </datalist>
              )}
            </div>
            <div className="mb-6">
              {types.map((tp) => (
                <div className={`flex items-center gap-2 mb-1`} key={tp.slug}>
                  <Radio
                    id={`radio-type-${tp.slug ?? tp.slug}`}
                    name="radio-type"
                    value={tp.slug}
                    onClick={() => setType(tp.slug)}
                    defaultChecked={type === tp.slug}
                  />
                  <Label htmlFor={`radio-type-${tp.slug ?? tp.slug}`}>
                    { tp.type }
                  </Label>
                </div>
              ))}
            </div>
            <div className="mb-6">
              <div className="mb-1 block">
                <Label
                  htmlFor="upload-form"
                  value="Upload do Formulário"
                  className="text-gray-700"
                />
              </div>
              <FileInput
                id="upload-form"
                defaultValue={""}
                className="border rounded-lg"
                onChange={handleFile}
              />
            </div>
            <hr/>
            <div className="mt-4 mb-5 group" ref={providerInfoRef}>
              <h5
                className="text-lg font-medium text-gray-900 mb-2 cursor-pointer flex justify-between items-center"
                onClick={() => {
                  if(!providerInfoRef.current) return;
                  providerInfoRef.current.classList.toggle('show');
                }}
              >
                <div>
                  Dados do Fornecedor
                  <span className="font-light text-gray-600 text-sm block -mt-1">(opcional)</span>
                </div>

                <ChevronDownIcon className="group-[.show]:rotate-180"/>
              </h5>
              <div className={`
                transform opacity-0 scale-95 hidden

                group-[.show]:block
                group-[.show]:opacity-100
                group-[.show]:scale-100
              `}>
                <div className="mb-4">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="new-request-provider-name"
                      value="Email"
                    />
                  </div>
                  <TextInput
                    value={supplierEmail}
                    onChange={(e) => setSupplierEmail(e.target.value)}
                    id="new-request-provider-name"
                    type="email"
                    placeholder="Digite o email do fornecedor"
                  />
                </div>
                <div className="mb-4">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="new-request-provider-name"
                      value="Nome"
                    />
                  </div>                  
                  <div className="flex flex-col">
                    <div className="relative w-full">
                      <input
                        className={`
                          block w-full
                          border bg-gray-50 border-gray-300 text-gray-900 
                          focus:border-blue-500 focus:ring-blue-500 
                          dark:border-gray-600 dark:bg-gray-700 dark:text-white 
                          dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500
                          rounded-lg p-2.5 text-sm
                          read-only:bg-gray-200
                        `}
                        id="new-request-provider-name"
                        type="text"
                        placeholder="Digite o nome do fornecedor"
                        value={supplierName}
                        onChange={(e) => setSupplierName(e.target.value)}
                        readOnly={!!suppliers?.find(s => s.email === supplierEmail)}
                      />
                    </div>
                    <span className="text-xs text-gray-400 pt-0.5">Para alterar nome do fornecedor solicite ao administrador.</span>
                  </div>
                </div>
                <div className="mb-5">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="new-request-provider-name"
                      value="Contato"
                    />
                  </div>
                  <TextInput
                    value={supplierContato}
                    onChange={(e) => setSupplierContato(e.target.value)}
                    id="new-request-provider-name"
                    list="datalist-supplier-contact"
                    type="text"
                    placeholder="Digite o nome do contato"
                    ref={supplierContactRef}
                  />
                  {oldSupplierContacts && oldSupplierContacts.length > 0 && (
                    <datalist id="datalist-supplier-contact">
                      {oldSupplierContacts.map((oldSupplierContact) => (
                        <option value={oldSupplierContact} key={oldSupplierContact}/>
                      ))}
                    </datalist>
                  )}
                </div>
              </div>
            </div>
            <hr/>
          </div>
          <button
            type="submit"
            className={`${shortclass.button.primary} w-full mt-6`}
          >Cadastrar</button>
        </form>
        {loading && <Loading className={`
          absolute -top-16 -left-2 -right-2 h-[calc(100%+5rem)] w-[calc(100%+1rem)]
          bg-semi-transparent
        `}/>}
      </div>
    </SlideOver>
  );
}