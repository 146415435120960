import moment from "moment";
import { OrderHistoryType, ResultAndResponse } from "../types";
import { api, headerBearer } from "./api";

let cacheOrderHistory : {
  order_id: string,
  token: string,
  data: ResponseGetOrderHistory
}[] = [];
export const clearCacheOrderHistoryOrderId = (order_id: string) => {
  cacheOrderHistory = cacheOrderHistory.filter(cache => cache.order_id !== order_id);
} 
interface ResponseGetOrderHistory extends ResultAndResponse{
  data: OrderHistoryType[]
}
export const getOrderHistory = async (token: string, order_id: string) => {
  try{
    let findedCache = cacheOrderHistory.find(cache => cache.token === token && cache.order_id === order_id);
    if(findedCache && findedCache.data.result){
      console.log('[cache-order-history]');
      return findedCache.data;
    }
  }catch(e){ console.error(e); }

  const { data } = await api.get<ResponseGetOrderHistory>(`/order/history/${order_id}`, headerBearer(token));

  let parsed = {
    result: data.result,
    response: data.response,
    data: (data.data ? data.data.map((history) => ({
      ...history,
      date: moment(history.created_at).format('DD/MM/YYYY H:mm'),
    })): data.data)  
  };

  console.log('[request-order-history]');

  cacheOrderHistory.push({ token, order_id, data: parsed });
  return parsed;
}