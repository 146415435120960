import { Tooltip } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { PossiblePermissions } from "../../types";
import { ProjectIcon, TableIcon, UsersIcon, WorkflowIcon } from "../SvgIcons";

const WORKFLOW_MODULE = process.env.REACT_APP_WORKFLOW_MODULAR;
export const MenuAdminAside = () => {
  const { user } = useAuth();
  const [canManagement, setCanManagement] = useState(false);

  useEffect(() => {
    setCanManagement(
      !!user?.permitions_slug?.includes(PossiblePermissions.GESTAO)
    );
  },[user]);

  return (
    <aside className="min-w-[4rem]">
      <div className={`
        bg-gray-700 rounded
        flex sm:justify-start sm:flex-col gap-1.5
        w-full sm:w-fit relative
      `}>
        <Tooltip
          content="Usuários"
          trigger="hover"
          className="sm:translate-x-[4.5rem] sm:translate-y-[143%]" 
        >
          <Link
            to={'/painel-adm'}
            className="p-3 hover:brightness-75 flex"
          ><UsersIcon className="w-8 text-white"/></Link>
        </Tooltip>
        <Tooltip
          content="Projetos"
          trigger="hover"
          className="sm:translate-x-[4.5rem] sm:translate-y-[143%]"  
        >
          <Link
            className="p-3 hover:brightness-75 flex"
            to={'/painel-adm/projetos'}
          ><ProjectIcon className="w-8 text-white"/></Link>
        </Tooltip>
        <Tooltip
          content="Gestão"
          trigger="hover"
          className={`sm:translate-x-[4.5rem] sm:translate-y-[143%]`}
        >
          <Link
            className={`p-3 hover:brightness-75 flex ${!canManagement ? 'brightness-75 cursor-default':''}`}
            to={canManagement ? '/painel-adm/gestao' : '#'}
          ><TableIcon className="w-8 text-white"/></Link>
        </Tooltip>
        
        {WORKFLOW_MODULE && user && (
          <Tooltip
            content="Gerenciar Fluxos"
            trigger="hover"
            className="sm:translate-x-[4.5rem] sm:translate-y-[113%]"  
          >
            <a
              className="p-3 hover:brightness-75 flex"
              href={`${WORKFLOW_MODULE}?token=${user.token}`}
            ><WorkflowIcon className="w-8 text-white"/></a>
          </Tooltip>
        )}
      </div>
    </aside>
  );
}