import { OrderType } from "../types";
import { api, headerBearer } from "./api"
import { clearCacheOrderHistoryOrderId } from "./orderHistory";

interface CreateDepositResponseType {
  result: boolean,
  response: string,
  data?: OrderType
}
export const createDeposit = async (data: {
  cnpj: string,
  banco: string,
  agencia: string,
  contacorrente: string,
  orderId: string
}, token: string) : Promise<CreateDepositResponseType> => {
  try{
    const response = await api.post('/deposito', data, headerBearer(token));

    clearCacheOrderHistoryOrderId(data.orderId);

    return response.data;
  }catch(e){
    console.error(e);

    return {
      result: false,
      response: 'Houve um erro inesperado'
    };
  }
}
export const deleteDeposit = async (order_id: string, token: string): Promise<void> => {
  const { data } = await api.delete(`/deposito/${order_id}`, headerBearer(token));

  clearCacheOrderHistoryOrderId(order_id);

  return data;
}
export const sendDocs = async (data: FormData, orderId: string, po_id: string, token: string) => {
  const response = await api.put(`/docs/${orderId}/${po_id}`, data, headerBearer(token));

  clearCacheOrderHistoryOrderId(orderId);

  return response.data;
}