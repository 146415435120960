import { Card } from "flowbite-react";

import { Loading } from "../../components/Loading";
import { Wrapper } from "../../components/Wrapper";

import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";

import logo from '../../assets/default-client-logo.png';
import { shortclass } from "../../styles/styles";
import { useEffect, useState } from "react";

export const Profile = () => {
  const { user, changeClient } = useAuth();
  const { toast } = useNotify();
  const [permissions, setPermissions] = useState<string[]>([]);
  
  useEffect(() => {
    if(user && user.permitions_slug){
      setPermissions(user.permitions_slug);
    }
  },[user]);

  async function handleChangeClient(client_id: string, client_name: string){
    if(!user) return;
    toast.promise(changeClient(client_id, client_name, user.token), {
      'pending': 'Alterando empresa',
      'error': 'Houve um erro ao alterar a empresa',
      'success': 'Empresa alterada com sucesso'
    });
  }

  return (
    <Wrapper>
      {!user ? <Loading className="h-[calc(100vh-14.5rem)] rounded-xl"/> : (
        <div className="w-full">
          <Card className="mb-6">
            <div className="flex items-center pb-4 gap-4">
              <img
                className="h-14 w-14 rounded-full shadow-lg object-cover"
                src={user.picture}
                alt="Bonnie image"
              />
              <div>
                <h5 className="mb-0 text-xl font-medium text-gray-900 dark:text-white">
                  {user.name}
                </h5>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {user.email}
                </span>
              </div>
            </div>
            <div>
              <div className="flex space-x-3">
                <button
                  type="button"
                  className={shortclass.button.primary}
                  onClick={() => toast.warning('Em desenvolvimento')}
                >Editar</button>
                <button
                  type="button"
                  className="inline-flex items-center rounded-lg border border-gray-300 bg-white py-2 px-4 text-center text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                  onClick={() => toast.warning('Em desenvolvimento')}
                >Desativar</button>
              </div>
            </div>
          </Card>

          <div className="flex flex-col lg:flex-row gap-4 items-start">
            <Card className="mb-6 w-full lg:w-[28rem] min-h-[15rem]">
              <h5 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Empresas</h5>
              {user.clients && user.clients.map(client => (
                <div
                  className="border-b last:border-b-0 cursor-pointer"
                  onClick={() => client.id === user.current_client ? {} : handleChangeClient(client.id, client.nome_fantasia)}
                  key={client.id}
                >
                  <div className="flex items-center pb-4">
                    <img
                      alt={client.nome_fantasia}
                      className="rounded-full object-cover w-8 h-8"
                      src={client.picture ?? logo}
                      onError={(e) => {
                        let img = e.target as HTMLImageElement;
                        img.src = logo;
                      }}
                    />
                    <div className="ml-3 flex-1 flex justify-between">
                      <span className="whitespace-nowrap font-semibold text-sm text-ellipsis overflow-hidden max-w-[calc(100%-7rem)] block">{client.nome_fantasia}</span>
                      {client.id === user.current_client && (
                        <span className="inline-flex items-center justify-center rounded bg-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500 dark:bg-gray-700 dark:text-gray-400">
                          Selecionado
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Card>
            <Card className="mb-6 w-full min-h-[15rem]">
              <div>
                <h5 className="text-lg font-medium text-gray-900 dark:text-white">
                  Permissões
                  <span className="text-sm text-gray-700 block">na empresa selecionada</span>
                </h5>
              </div>
              <ul className="grid grid-cols-2 gap-1">
                {permissions.map((permition, i) => (
                  <li className="p-2 my-1 text-sm rounded-lg border bg-gray-100 hover:bg-gray-200" key={`${permition}-${i}`}>{permition}</li>
                ))}
              </ul>
            </Card>
          </div>
        </div>
      )}
    </Wrapper>
  );
}