import { Link } from "react-router-dom"
import { ChevronLeftIcon } from "../../components/SvgIcons";
import { shortclass } from "../../styles/styles";
import  "./style.css";

export const PageError = () => {
  return (
    <div className="page_error bg-gradient-primary">
      <div className="page_error__content">
        <h1 className="text-8xl font-semibold text-gray-50 mb-1">404</h1>
        <h2 className="text-lg uppercase font-semibold text-gray-400 mb-3">Página não encontrada</h2>
        <p className="text-gray-400/80">A página que você está procurando não existe ou foi removida.</p>
        <div>
          <Link to="/" className={`
            ${shortclass.button.normal}
            text-gray-700 font-semibold focus:ring-gray-300 bg-gray-100 hover:bg-gray-200
            mt-4 w-fit mx-auto uppercase text-xs items-center
          `}>
            <ChevronLeftIcon />
            Voltar para o início</Link>
        </div>
      </div>
    </div>
  )
}
