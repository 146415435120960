import { ManagementLinkType, ResultAndResponse } from "../types";
import { api, headerBearer } from "./api"

interface ResponseGetManageLinks extends ResultAndResponse{
  data?: ManagementLinkType[]
}
export const getManageLinks = async (token: string) : Promise<ResponseGetManageLinks> => {
  try{
    const { data } = await api.get<ResponseGetManageLinks>('manage-links', headerBearer(token));

    let parsed = {
      ...data,
      data: (data.data ? data.data.map((user: any) => ({
        name: user.name,
        email: user.email,
        picture: user.picture,
        user_id: user.id,
        src: user.managementLink.length > 0 ? user.managementLink[0].src : null
      })) : data.data)
    };
    
    return parsed;
  }catch(e: any){
    console.error(e);

    if(e?.response?.data && e?.response?.data.response) return e.response.data;

    return {
      result: false,
      response: 'Não foi possível carregar os usuários dessa empresa'
    };
  }
}
interface ResponseManageLink extends ResultAndResponse{
  data?: {
    clientId: string,
    src: string,
    userId: string
  }
}
export const getManageLink = async (token: string) : Promise<ResponseManageLink> => {
  try{
    const { data } = await api.get(`manage-link`, headerBearer(token));
    return data;
  }catch(e: any){
    console.error(e);

    if(e?.response?.data && e?.response?.data.response) return e.response.data;

    return {
      result: false,
      response: 'Não foi possível carregar o link de gestão'
    };
  }
}
export const saveManageLink = async (token: string, src: string, user_id?: string) : Promise<ResponseManageLink> => {
  try{
    if(user_id){
      const { data } = await api.post(`manage-link`, {
        user_id,
        src
      }, headerBearer(token));

      return data;
    }else{
      const { data } = await api.put(`manage-all-links`, {
        src
      }, headerBearer(token));
      return data;
    }
  }catch(e: any){
    console.error(e);

    if(e?.response?.data && e?.response?.data.response) return e.response.data;

    return {
      result: false,
      response: 'Não foi possível carregar os usuários dessa empresa'
    };
  }
}