import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { Wrapper } from "../../components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { getManageLink } from "../../services/manageLink";

const defaultSrc = 'https://app.powerbi.com/view?r=eyJrIjoiYWExMTYxMWYtMmNiZS00MjUxLThlYjctMGYwYmM5ZDJlOTM4IiwidCI6IjM2YmE0ZTJhLTQ3MmEtNGNkNy05ODk4LTg2MjM1MTA2MWYyYSJ9';
export const Management = () => {
  const { user } = useAuth();

  const [src, setSrc] =  useState<string | null>(null);
  const [loading, setLoading] =  useState(true);
  
  useEffect(() => {
    (async () => {
      if(!user) return;
      setLoading(true);
      const res = await getManageLink(user.token);
      if(res.result && res.data){
        setSrc(res.data.src);
      }
      setLoading(false);
    })()
  },[user]);
  return (
    <Wrapper module_name="Gestão" options={{ disabled: ['menu-aside'] }}>
      {loading ? <Loading/> : (
        <iframe
          title="BaseConsolidada-V3"
          className="w-[1280px] xl:h-[730px] h-[56vw]"
          src={src ?? defaultSrc}
          allowFullScreen={true}
        ></iframe>
      )}
    </Wrapper>
  );
}