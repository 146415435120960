import { createContext, useContext, useEffect, useState } from "react";
import { authenticate, AuthenticateType, changeCurrentClient, logout } from "../services/authenticate";
import { storageKeys } from "../services/cache";
import { ResultAndResponse, StorageKeyType, User } from "../types";

interface AuthContextType {
  user: User | undefined,
  signIn: (data: AuthenticateType) => Promise<void>,
  signOut: () => Promise<ResultAndResponse>,
  loadUser: () => User | undefined,
  isAuthenticated: () => boolean,
  changeClient: (client_id: string, client_name: string, token: string) => Promise<ResultAndResponse>,
  storageKey: StorageKeyType
}
export const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const storageKey : StorageKeyType = storageKeys;

  const [user, setUser] = useState<User | undefined>();
  
  useEffect(() => {
    loadUser();
  }, []);

  // #region AUTH
  const signIn = async (data: AuthenticateType) => {
    let user = await authenticate(data);

    sessionStorage.setItem(storageKey.user, JSON.stringify(user));
    sessionStorage.setItem(storageKey.token, user.token);

    if(data.remember_me){
      localStorage.setItem(storageKey.user, JSON.stringify(user));
      localStorage.setItem(storageKey.token, user.token);
    }

    setUser(user);
  };
  const signOut = async () : Promise<ResultAndResponse> => {
    let data = await logout();
    if(data.result){
      sessionStorage.removeItem(storageKey.user);
      sessionStorage.removeItem(storageKey.token);
      localStorage.removeItem(storageKey.user);
      localStorage.removeItem(storageKey.token);

      setUser(undefined);
    }
    return data;
  };
  const isAuthenticated = () : boolean => {
    if(user && user.token) return true;
    return !!loadUser(false);
  }
  // #endregion AUTH
  const loadUser = (updateState = true) : User | undefined => {
    if(user) return user;

    let tempUser =  sessionStorage.getItem(storageKey.user);
    let tempToken = sessionStorage.getItem(storageKey.token);

    if(!(tempUser && tempToken)){
      tempUser = localStorage.getItem(storageKey.user);
      tempToken = localStorage.getItem(storageKey.token);

      if(tempUser && tempToken){
        sessionStorage.setItem(
          storageKey.user, tempUser
        );
        sessionStorage.setItem(
          storageKey.token, tempToken
        );
      }
      else return undefined;
    }

    let parsedUser : User | null = null;
    try{ parsedUser = JSON.parse(tempUser); }
    catch(e){
      console.error(e);
      return undefined;
    }

    if(!parsedUser) return undefined;
    if(updateState) setUser({
      ...parsedUser,
      token: tempToken
    });

    return {
      ...parsedUser,
      token: tempToken
    } as User;
  }
  const updateStorage = (updatedUser: User) => {
    if(localStorage.getItem(storageKey.user)){
      localStorage.setItem(storageKey.user, JSON.stringify(updatedUser));
      localStorage.setItem(storageKey.token, updatedUser.token);
    }
    
    sessionStorage.setItem(storageKey.user, JSON.stringify(updatedUser));
    sessionStorage.setItem(storageKey.token, updatedUser.token);
  }
  const changeClient = async (client_id: string, client_name: string, token: string) : Promise<ResultAndResponse> => {
    if(!user) return {
      result: false,
      response: 'Faça o login novamente'
    };

    const response = await changeCurrentClient(client_id, token);
    if(response.result && response.data){
      setUser((old) => {
        if(!old) return;
        const { token, permitions, permitions_slug } = response.data!;

        let updatedUser = {
          ...old,
          client_name,
          token,
          permitions,
          permitions_slug,
          current_client: client_id
        };
        
        updateStorage(updatedUser);
  
        return updatedUser;
      });
  
      return {
        result: true,
        response: 'Empresa alterada'
      };
    }
    return response;
  }

  return (
    <AuthContext.Provider value={{
      user,

      signIn,
      signOut,
      loadUser,
      isAuthenticated,

      changeClient,

      storageKey
    }}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export const invalidTokenJWT = () => {
  sessionStorage.removeItem(storageKeys.user);
  sessionStorage.removeItem(storageKeys.token);
  localStorage.removeItem(storageKeys.user);
  localStorage.removeItem(storageKeys.token);

  window.location.reload();
}