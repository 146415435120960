import { Routes, Route, HashRouter, Navigate, useLocation } from 'react-router-dom';

import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { PaymentModule } from './pages/PaymentModule';
import { Icons } from './pages/Icons';
import { useAuth } from './contexts/AuthContext';
import { Warning } from './pages/Warning';
import { Profile } from './pages/Profile';
import { DocumentTemplate } from './pages/DocumentTemplate';
import { PaymentDocs } from './pages/PaymentDocs';
import { AdminUsers } from './pages/Admin/AdminUsers';
import { AdminProjects } from './pages/Admin/AdminProjects';
import { PossiblePermissions } from './types';
import { PageError } from './pages/Error';
import { Maintenance } from './pages/Maintenance';
import { Management } from './pages/Management';
import { storageKeys } from './services/cache';
import { AdminManagement } from './pages/Admin/AdminManagement';

export const HandleRoutes = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/login" element={
          <RequireNotAuth>
            <Login />
          </RequireNotAuth>
        }/>
        <Route index element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }/>
        <Route path="/alertas" element={
          <RequireAuth moduleName={PossiblePermissions.CONTAS_A_PAGAR}>
            <Warning />
          </RequireAuth>
        }/>
        <Route path="/compras-e-contas-a-pagar" element={
          <RequireAuth moduleName={PossiblePermissions.CONTAS_A_PAGAR}>
            <PaymentModule />
          </RequireAuth>
        }/>
        <Route path="/documentos-para-pagamento/:id" element={
          <RequireAuth moduleName={PossiblePermissions.CONTAS_A_PAGAR}>
            <PaymentDocs />
          </RequireAuth>
        }/>
        <Route path="/perfil" element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }/>
        <Route path="/modelos-de-documentos" element={
          <RequireAuth moduleName={PossiblePermissions.CONTAS_A_PAGAR}>
            <DocumentTemplate />
          </RequireAuth>
        }/>
        <Route path="/icones" element={
          <RequireAuth isAdmin>
            <Icons />
          </RequireAuth>
        }/>
        <Route path="/painel-adm" element={
          <RequireAuth isAdmin>
            <AdminUsers />
          </RequireAuth>
        }/>
        <Route path="/painel-adm/projetos" element={
          <RequireAuth isAdmin>
            <AdminProjects />
          </RequireAuth>
        }/>
        <Route path="/painel-adm/gestao" element={
          <RequireAuth isAdmin moduleName={PossiblePermissions.GESTAO}>
            <AdminManagement />
          </RequireAuth>
        }/>
        <Route path="/gestao" element={
          <RequireAuth moduleName={PossiblePermissions.GESTAO}>
            <Management />
          </RequireAuth>
        }/>
        <Route path="*" element={<PageError />}/>
      </Routes>
    </HashRouter>
  )
}

function RequireAuth({ children, isAdmin = false, moduleName }: { isAdmin?: boolean, moduleName?: PossiblePermissions, children: JSX.Element }) {
  const { isAuthenticated, user, loadUser } = useAuth();
  let location = useLocation();
  
  let maintenance = IsMaintenance();
  if(maintenance) return maintenance;

  // FAZER VERIFICAÇÃO DE ROTAS DE ADM
  if (!isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  
  if (isAdmin || moduleName) {
    let hasPermission = true;
    try {
      let loggedUser = user;
      if (!loggedUser) loggedUser = loadUser();

      if (loggedUser) {
        let permitions = loggedUser.permitions_slug ?? [];

        if(isAdmin){
          if(!permitions.includes(PossiblePermissions.ADMIN)) hasPermission = false;
        }
        if(moduleName){
          if(!permitions.includes(moduleName)) hasPermission = false;
        }
      }
      else hasPermission = false;
    } catch (e) {
      console.error(e)
      hasPermission = false;
    }

    if (!hasPermission) return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
function RequireNotAuth({ children }: { children: JSX.Element }){
  const { isAuthenticated } = useAuth();
  let location = useLocation();

  let maintenance = IsMaintenance();
  if(maintenance) return maintenance;

  if (isAuthenticated()) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
function IsMaintenance(){
  if(process.env.REACT_APP_UNDER_MAINTENANCE === 'true'){
    let isDev = localStorage.getItem(storageKeys.developers);
    if(!isDev) return <Maintenance/>;
  }
  return null;
}