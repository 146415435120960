import { Card, Label } from "flowbite-react";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { Wrapper } from "../../components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { Client, ManagementLinkType } from "../../types";

import powerBiLogo from '../../assets/power_bi.png';
import logo from '../../assets/default-client-logo.png';
import { useNotify } from "../../contexts/NotifyContext";
import { getManageLinks, saveManageLink } from "../../services/manageLink";
import { CloseIcon } from "../../components/SvgIcons";

export const AdminManagement = () => {
  const { user } = useAuth();
  const { toast } = useNotify();
  const [client, setClient] = useState<Client>();
  const [users, setUsers] = useState<ManagementLinkType[]>([]);
  const [selectedUser, setSelectedUser] = useState<ManagementLinkType | null>(null);
  const [src, setSrc] = useState('');

  useEffect(() => {
    if(!user) return;
    loadClient();
    loadUsers();
  },[user]);
  
  async function loadClient(){
    if(!user || !user.current_client) return;

    let findedClient = user.clients?.find(c => c.id === user.current_client);
    if(findedClient) setClient(findedClient);
    else toast.error('Empresa não encotrada');
  }
  async function loadUsers(){
    if(!user) return;

    const res = await getManageLinks(user.token);
    if(res.result && res.data) setUsers(res.data);
    else toast.error(res.response);
  }
  function handleSelectUser(user: ManagementLinkType){
    setSelectedUser(user);
    setSrc(user.src ?? '');
  }
  function handleSubmit(userId?: string){
    if(!user) return;
    
    if(!src){
      toast.error('É obrigatório adicionar o Link');
      return;
    }

    const submit = async (userId?: string) => {
      if(!user) return;

      const res = await saveManageLink(user.token, src, userId);

      if(!res.result){
        toast.error(res.response);
        return;
      }

      toast.success(res.response);

      setUsers(prevState => prevState.map((prev) => ({
        ...prev,
        ...(!userId || (userId  && prev.user_id === userId) ? {src} : {})
      })))

      setSelectedUser(null);
      setSrc('');
    }

    if(userId) submit(userId);
    else{
      if(window.confirm("Tem certeza que irá alterar o link para todos os usuários da empresa?"))  submit();
    }
  }

  return (
    <Wrapper
      module_name="Painel Adm"
      isAdmin
    >
      {(!user || !client) ? <Loading className="h-[calc(100vh-14.5rem)] rounded-xl"/> : (
        <div className="w-full">
          <Card className="mb-6">
            <div className="flex items-center gap-4">
              <img
                className="h-14 w-14 rounded-full shadow-lg object-cover"
                alt={client.nome_fantasia}
                src={client.picture ?? logo}
                onError={(e) => {
                  let img = e.target as HTMLImageElement;
                  img.src = logo;
                }}
              />
              <div>
                <h5 className="mb-0 text-xl font-medium text-gray-900 dark:text-white">
                  {client.nome_fantasia}
                </h5>
              </div>
            </div>
          </Card>
          <div className="flex gap-4 items-start flex-col lg:flex-row">
            <div className={`
              flex rounded-lg border border-gray-200 bg-white 
              shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col gap-2
              mb-6 w-full lg:w-[24rem] xl:w-[28rem] min-h-[15rem] py-6
            `}>
              <div className="flex items-center justify-between gap-2 px-6">
                <h5 className="text-lg font-medium text-gray-900 dark:text-white">Usuários</h5>
              </div>
              <div className="overflow-y-auto max-h-96">
                {users && users.map((usr) => (
                  <div
                    className={`border-b last:border-b-0 hover:bg-gray-100 ${selectedUser?.user_id === usr.user_id ? 'bg-gray-200':''} rounded-lg cursor-pointer px-2 mx-4`}
                    onClick={() => handleSelectUser(usr)}
                    key={usr.user_id}
                  >
                    <div className="flex items-center py-2">
                      <img
                        alt={usr.name}
                        className="rounded-full object-cover w-8 h-8"
                        src={usr.picture}
                      />
                      <div className="ml-3 flex-1 flex justify-between items-center">
                        <div className="flex flex-col items-start">
                          <span className="whitespace-nowrap text-ellipsis overflow-hidden block font-medium text-gray-800">{usr.name}</span>
                          <span className="whitespace-nowrap text-ellipsis overflow-hidden block text-xs text-gray-700">{usr.email}</span>
                        </div>
                      </div>
                      
                      <img
                        className={`rounded-full object-cover w-8 h-8 ${usr.src ? '':'brightness-75'}`}
                        src={powerBiLogo}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`
              flex flex-1 rounded-lg border border-gray-200 bg-white 
              shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col gap-2
              mb-6 w-full py-6
            `}>
              <div className="flex items-center justify-between gap-2 px-6">
                <h5 className="text-lg font-medium text-gray-900 dark:text-white">Alterar link de gestão</h5>
              </div>
              <div className="px-6">
                <div className="flex flex-col">
                  <div className="relative w-full">
                    <div className="mb-1">
                      <Label className="text-xs font-medium" htmlFor="new-user-name">Link da Dashboard</Label>
                    </div>
                    <div className="mb-2">
                      <input
                        className={`
                          block w-full
                          border bg-gray-50 border-gray-300 text-gray-900 
                          focus:border-blue-500 focus:ring-blue-500 
                          dark:border-gray-600 dark:bg-gray-700 dark:text-white 
                          dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500
                          rounded-lg p-2.5 text-sm
                          read-only:bg-gray-200
                        `}
                        id="new-user-name"
                        type="text"
                        placeholder="Digite o src da dashboard de gestão"
                        value={src}
                        onChange={(e) => setSrc(e.target.value)}
                      />
                    </div>

                    {selectedUser && (
                      <div
                        className="flex items-center gap-3 py-2 mb-4 self-start w-fit max-w-full bg-gray-200 hover:bg-gray-300 rounded-xl px-3 cursor-pointer"
                        onClick={() => setSelectedUser(null)}
                      >
                        <img
                          alt={selectedUser.name}
                          className="rounded-full object-cover w-6 h-6"
                          src={selectedUser.picture}
                        />

                        <div className="flex-1 flex justify-between items-center">
                          <div className="flex flex-col items-start">
                            <span className="whitespace-nowrap text-ellipsis overflow-hidden block text-sm font-medium text-gray-800">{selectedUser.name}</span>
                            <span className="whitespace-nowrap text-ellipsis overflow-hidden block text-[10px] leading-none text-gray-700">{selectedUser.email}</span>
                          </div>
                        </div>

                        <CloseIcon width="10" className="ml-6 block"/>
                      </div>
                    )}
                    <div className="flex gap-4 mt-2">
                      {selectedUser && (
                        <button
                          type="button"
                          className={`
                            flex justify-center 
                            rounded-lg border border-transparent border-gray-400
                            py-2 px-4
                            text-xs font-semibold uppercase
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                          text-gray-700 focus:ring-gray-100 bg-gray-200 hover:bg-gray-400
                          `}
                          onClick={() => handleSubmit(selectedUser.user_id)}
                        >Alterar p/ {selectedUser.name}</button>
                      )}
                      <button
                        type="button"
                        className={`
                          flex justify-center 
                          rounded-lg border border-transparent 
                          py-2 px-4
                          text-xs font-semibold uppercase
                          focus:outline-none focus:ring-2 focus:ring-offset-2
                        text-white focus:ring-primary-500 bg-primary-600 hover:bg-primary-700
                        `}
                        onClick={() => handleSubmit()}
                      >Alterar para Todos</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}