import Datepicker from "react-tailwindcss-datepicker";
import { useEffect, useRef, useState } from 'react';
import { Tooltip, Checkbox } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";

// #region ASIDE-IMAGE-ICONS
import AlarmAsideSvg from '../../assets/aside-icons/alarm.svg';
import DeleteAsideSvg from '../../assets/aside-icons/delete.svg';
import NewAsideSvg from '../../assets/aside-icons/new.svg';
import SearchAsideSvg from '../../assets/aside-icons/search.svg';
import UpdateAsideSvg from '../../assets/aside-icons/update.svg';
import DocModelsAside from '../../assets/aside-icons/library.svg';
// #endregion ASIDE-IMAGE-ICONS
import { CloseIcon } from '../SvgIcons';
import { FilterProps } from ".";
import { useNotify } from "../../contexts/NotifyContext";

interface MenuAsideProps{
  onDeleteMultiple?: () => Promise<void>,
  onOpenNewRequest: () => void,
  filter?: FilterProps,
  disabled?: boolean
}
let timerRefreshStatus : NodeJS.Timeout;
let timerStatus : NodeJS.Timeout;
export const MenuAside = ({
  onDeleteMultiple,
  onOpenNewRequest,
  filter,
  disabled
}: MenuAsideProps) => {
  const { toast } = useNotify();
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState<string[]>(
    filter?.status.filterStatus ?? []
  );
  
  useEffect(() => {
    if(!filter) return;
    const callback = () => {
      if(filter.status.filterStatus === selectedStatus) return;
      
      setSelectedStatus(filter.status.filterStatus ?? []);
    }

    if(timerRefreshStatus) clearTimeout(timerRefreshStatus);
    timerRefreshStatus = setTimeout(callback, 1000);
  },[filter]);
  useEffect(() => {
    if(!filter) return;
    const callback = () => {
      if(selectedStatus === filter.status.filterStatus) return;
      
      filter.status.setFilterStatus(selectedStatus);
      if(filter.onPageStart) filter.onPageStart();
    };

    if(timerStatus) clearTimeout(timerStatus);
    timerStatus = setTimeout(callback, 2000);
  },[selectedStatus]);
  let timerRequester : NodeJS.Timeout;
  function handleChangeRequester(e: any){
    if(!filter) return;
    const callback = (value: string) => {      
      filter.requester.setFilterRequester(value);
      if(filter.onPageStart) filter.onPageStart();
    };

    if(timerRequester) clearTimeout(timerRequester);
    timerRequester = setTimeout(() => callback(e.target.value as string), 1000);
  }
  let timerNumberPO : NodeJS.Timeout;
  function handleChangeNumberPO(e: any){
    if(!filter) return;
    const callback = (value: string) => {
      filter.po.setFilterNumberPO(value);
      if(filter.onPageStart) filter.onPageStart();
    };

    if(timerNumberPO) clearTimeout(timerNumberPO);
    timerNumberPO = setTimeout(() => callback(e.target.value as string), 1000);
  }
  function handleDelete(){
    if(onDeleteMultiple){
      onDeleteMultiple();
    }
    else{
      navigate('/compras-e-contas-a-pagar');
      toast.info('Selecione os registros que deseja excluir');
    }
  }
  // #region HANDLE ASIDE DROPDOWN
  const asideDropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: any) => {
    if(asideDropdownRef.current && !asideDropdownRef.current.contains(event.target)){
      asideDropdownRef.current.classList.remove('show');
    }
  };
  const statusDropdownRef = useRef<HTMLLabelElement>(null);
  const handleClickOutsideStatus = (event: any) => {
    if(statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)){
      statusDropdownRef.current.classList.remove('show');
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutsideStatus);    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutsideStatus);
    };
  },[]);
  // #endregion HANDLE ASIDE DROPDOWN

  return (
    <aside className="min-w-[4rem]">
      <div className={`
        bg-gray-700 rounded
        flex justify-between sm:justify-start sm:flex-col gap-1.5
        w-full sm:w-fit relative
      `}>
        <Tooltip
          content="Nova Solicitação"
          trigger="hover"
          className="sm:!left-0 sm:translate-x-[4rem] sm:translate-y-[120%]"
        >
          <button
            type="button"
            className={`p-3 hover:brightness-75 disabled:brightness-75`}
            onClick={onOpenNewRequest}
            disabled={disabled}
          ><img src={NewAsideSvg} className="w-8"/></button>
        </Tooltip>
        <Tooltip
          content="Lista"
          trigger="hover"
          className="sm:!left-0 sm:translate-x-[4rem] sm:translate-y-[143%]"
        >
          <button
            type="button"
            className="p-3 hover:brightness-75 disabled:brightness-75 flex"
            disabled={disabled}
            onClick={() => {
              if(filter){
                filter.date.setFilterDate({
                  startDate: null,
                  endDate: null
                })
                
                filter.status.setFilterStatus([]);
                setSelectedStatus([]);
                
                filter.po.setFilterNumberPO('');
                let elNumberPO = document.getElementById('input-filter-number-po') as HTMLInputElement;
                if(elNumberPO) elNumberPO.value = '';

                filter.requester.setFilterRequester('')
                let elRequester = document.getElementById('input-filter-requester') as HTMLInputElement;
                if(elRequester) elRequester.value = '';

              }
              navigate('/compras-e-contas-a-pagar');
            }}
          ><img src={UpdateAsideSvg} className="w-8"/></button>
        </Tooltip>
        <Tooltip
          content="Excluir"
          trigger="hover"
          className="sm:!left-0 sm:translate-x-[4rem] sm:translate-y-[143%]"
        >
          <button
            type="button"
            className="p-3 hover:brightness-75 disabled:brightness-75"
            onClick={handleDelete}
            disabled={disabled}
          ><img src={DeleteAsideSvg} className="w-8"/></button>
        </Tooltip>
        <Tooltip
          content="Alertas"
          trigger="hover"
          className="sm:!left-0 sm:translate-x-[4rem] sm:translate-y-[143%]"
        >
          <Link
            className={`p-3 hover:brightness-75 ${ disabled ? 'brightness-75 cursor-default' : ''} flex`}
            to={disabled ? '#' : '/alertas'}
          ><img src={AlarmAsideSvg} className="w-8"/></Link>
        </Tooltip>
        <div className="group" ref={asideDropdownRef}>
          <Tooltip
            content="Filtros"
            trigger="hover"
            className="sm:!left-0 sm:translate-x-[4rem] sm:translate-y-[143%] group-[.show]:hidden"
          >
            <div className="flex relative">
              <button
                type="button"
                className="p-3 hover:brightness-75 disabled:brightness-75"
                onClick={() => {
                  navigate('/compras-e-contas-a-pagar');

                  if(!asideDropdownRef.current) return;
                  asideDropdownRef.current.classList.toggle('show');
                }}
                disabled={disabled}
              ><img src={SearchAsideSvg} className="w-8"/></button>
              {filter && (
                <div className={`
                  transform opacity-0 scale-95 hidden
                  
                  group-[.show]:flex
                  group-[.show]:opacity-100
                  group-[.show]:scale-100

                  z-10 absolute
                  top-[2.6rem] right-0 
                  sm:left-[3.25rem] sm:top-auto sm:right-auto

                  mt-2 w-52 origin-top-right
                  rounded-md rounded-tl-none 
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                  bg-gray-700 shadow-none
                  p-2 gap-2 flex-col
                `}>  
                  <label
                    className={`rounded-xl bg-white flex items-center gap-1.5 pl-1 w-fit group/status relative`}
                    ref={statusDropdownRef}
                  >
                    <span className={`block w-3.5 h-3.5 ${
                      filter.status.filterStatus && filter.status.filterStatus.length > 0 ? 'bg-primary-500' : 'bg-gray-500'
                    } rounded-full`}></span>
                    <input
                      placeholder="Etapa"
                      className={`${
                        filter.status.filterStatus && filter.status.filterStatus.length > 0 ? 'w-max' : 'w-[5rem]'
                      } focus:w-max group-[.show]/status:w-max text-sm outline-0 p-0.5 max-w-[10.25rem] rounded-r-xl`}
                      value={selectedStatus ? filter.status.statusList?.filter(
                        sl => selectedStatus?.includes(sl.slug)
                      ).map(sl => sl.name).join(', ') : ''}
                      onClick={() => {
                        if(!statusDropdownRef.current) return;
                        statusDropdownRef.current.classList.toggle('show');
                      }}
                      readOnly
                    />
                    <div className={`
                      transform opacity-0 scale-95 hidden
                  
                      group-[.show]/status:flex
                      group-[.show]/status:opacity-100
                      group-[.show]/status:scale-100
      
                      z-10 absolute
                      bottom-0 left-0 right-0
      
                      w-full
                      rounded-lg 
                      border border-gray-200
                      focus:outline-none
                      bg-white shadow-lg
                    `}>
                      <span className="fixed top-1 right-2 text-gray-600 cursor-pointer hover:brightness-90 bg-white rounded-md p-1"><CloseIcon width='10' height='10'/></span>
                      <div className="max-h-[10rem] overflow-y-auto overflow-x-hidden p-2 gap-2 flex flex-col">
                        <label className="flex items-center text-sm text-gray-800 gap-1">
                          <Checkbox
                            onChange={() => {
                              setSelectedStatus((prevState?: string[]) : string[] => {
                                if(!prevState) return ['Todos'];
                                if(prevState.includes('Todos')) return prevState.filter(s => s !== 'Todos');
                                return ['Todos'];
                              });
                            }}
                            className="mt-0.5"
                            checked={selectedStatus.includes('Todos')}
                          />
                          Todos
                        </label>
                        {filter.status.statusList?.map((status, index) => (
                          <label className="flex items-center text-sm text-gray-800 gap-1" key={`filter-status-${index}`}>
                            <Checkbox
                              onChange={() => {
                                setSelectedStatus((prevState?: string[]) : string[] => {
                                  if(!prevState) return [status.slug];
                                  if(prevState.includes(status.slug)) return prevState.filter(s => s !== status.slug);
                                  return [
                                    ...prevState.filter(s => s !== 'Todos'),
                                    status.slug
                                  ];
                                });
                              }}
                              className="mt-0.5"
                              checked={selectedStatus.includes(status.slug)}
                            />
                            { status.name }
                          </label>
                        ))}
                      </div>
                    </div>
                  </label>
                  <label className={`rounded-xl bg-white overflow-hidden flex items-center gap-1.5 pl-1 w-fit`}>
                    <span className={`block w-3.5 h-3.5 ${
                      filter.requester.filterRequester ? 'bg-primary-500' : 'bg-gray-500'
                    } rounded-full`}></span>
                    <input
                      placeholder="Solicitante"
                      className={`${
                        filter.requester.filterRequester ? 'w-max' : 'w-[5rem]'
                      } focus:w-max text-sm outline-0 p-0.5 max-w-[10.25rem]`}
                      id="input-filter-requester"
                      defaultValue={filter.requester.filterRequester}
                      onChange={handleChangeRequester}
                    />
                  </label>
                  <label className={`rounded-xl bg-white flex items-center gap-1.5 pl-1 w-fit`}>
                    <span className={`block w-3.5 h-3.5 ${
                      filter.date.filterDate?.startDate ? 'bg-primary-500' : 'bg-gray-500'
                    } rounded-full`}></span>
                      <Datepicker
                        inputClassName={`${
                          filter.date.filterDate?.startDate ? 'w-max':'w-[5rem]'
                        } focus:w-max !font-normal text-sm outline-0 border-0 px-0 p-0 py-0 pl-0 pr-0 focus:shadow-transparent focus:ring-0 max-w-[10.25rem]`}
                        containerClassName="w-max"
                        value={filter.date.filterDate}
                        onChange={filter.date.setFilterDate}
                        placeholder={"Data"}
                        displayFormat={"DD/MM/YYYY"}
                      />
                  </label>
                  <label className={`rounded-xl bg-white overflow-hidden flex items-center gap-1.5 pl-1 w-fit`}>
                    <span className={`
                      block w-3.5 h-3.5 ${ filter.po.filterNumberPO ? 'bg-primary-500' : 'bg-gray-500'} rounded-full
                      `}></span>
                    <input
                      placeholder="Nº PO"
                      className={`
                        ${filter.po.filterNumberPO ? 'w-max' : 'w-[5rem]' }
                        focus:w-max text-sm outline-0 p-0.5 max-w-[10.25rem]
                      `}
                      id="input-filter-number-po"
                      defaultValue={filter.po.filterNumberPO}
                      onChange={handleChangeNumberPO}
                    />
                  </label>
                </div>
              )}
            </div>
          </Tooltip>
        </div>
        <Tooltip
          content="Modelos de Documentos"
          trigger="hover"
          className="sm:!left-0 sm:translate-x-[4rem] sm:translate-y-[120%]"
        >
          <Link
            className={`p-3 hover:brightness-75 ${disabled ? 'brightness-75 cursor-default':''} flex`}
            to={disabled ? '#' : '/modelos-de-documentos'}
          ><img src={DocModelsAside} className="w-8"/></Link>
        </Tooltip>
      </div>
    </aside>
  );
}