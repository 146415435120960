import { ResultAndResponse, User } from "../types";
import { api, headerBearer } from "./api"

interface ResponseUserType extends ResultAndResponse{
  data?: User
}
interface DataCreateUserType{
  name: string,
  password: string,
  email: string
}
export const createUser = async (data: DataCreateUserType, token: string) : Promise<ResponseUserType> => {
  try{
    const response = await api.post('/user', data, headerBearer(token));
    return {
      result: true,
      response: 'Usuário cadastrado',
      data: response.data
    };
  }catch(e: any){
    console.error(e);
    
    let errorMessage = 'Houve um erro inesperado';
    if(e.response.data.message) errorMessage = e.response.data.message;
    else if(e.response.data) errorMessage = e.response.data;

    return {
      result: false,
      response: errorMessage
    }
  }
}
export const searchUserByEmail = async (email: string, token: string) : Promise<ResponseUserType> => {
  try{
    const { data } = await api.get(`/user-email/${email}`, headerBearer(token));

    if(data.result) return {
      ...data,
      response: 'Usuário localizado'
    };

    return data;
  }catch(e){
    console.error(e);
    return {
      result: false,
      response: 'Houve um erro ao tentar localizar o usuário'
    };
  }
    
}