import { OrderFormattedType } from "../../types"

export const OrderHeader = ({order}:{
  order: OrderFormattedType
}) => (
  <h5 className={`
    text-2xl font-bold tracking-tight 
    text-gray-900 dark:text-white
    flex gap-2
  `}>
    <span className="text-gray-400">#</span>
    <span className="flex flex-col">
      <span>{order.poId}</span>
      <span className="text-gray-500 text-xs font-normal">
        {order.date}
        {order.date !== order.update && (
          <>&nbsp;&nbsp; - &nbsp; {order.update}</>
        )}
      </span>
    </span>
  </h5>
)