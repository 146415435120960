import { Wrapper } from '../../components/Wrapper';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../assets/default-client-logo.png';
import { useNotify } from '../../contexts/NotifyContext';

export const Home = () => {
  const { toast } = useNotify();
  const { user, changeClient } = useAuth();

  async function handleChangeClient(client_id: string, client_name: string){
    if(!user) return;
    toast.promise(changeClient(client_id, client_name, user.token), {
      'pending': 'Alterando empresa',
      'error': 'Houve um erro ao alterar a empresa',
      'success': 'Empresa alterada com sucesso'
    });
  }

  return (
    <Wrapper>
      <div className={`
        flex flex-wrap justify-center items-center
        w-full max-w-full
        overflow-y-auto
        rounded-lg bg-gray-200
      `} style={{ height: 'calc(100vh - 14rem)' }}>
        <div className="flex flex-wrap justify-center gap-4 m-6">
          {user?.clients?.map(client => (
            <div key={client.id} className={`
              w-[10rem] flex rounded-lg gap-2
              border border-gray-200 bg-white hover:bg-gray-100 shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col
              relative overflow-hidden group ${user.current_client === client.id ? 'active' : ''} cursor-pointer p-4
            `} onClick={() => user.current_client === client.id ? {} : handleChangeClient(client.id, client.nome_fantasia)}>
              <img
                alt={client.nome_fantasia}
                className="rounded-[50%] object-cover h-16 w-16 mx-auto border"
                src={client.picture ?? logo}
                onError={(e) => {
                  let img = e.target as HTMLImageElement;
                  img.src = logo;
                }}
              />
              
              <strong className="font-bold tracking-tight text-gray-800 break-words w-full text-center">
                <span className="text-ellipsis overflow-hidden whitespace-nowrap max-w-16 block">{ client.nome_fantasia }</span>
                <span className="hidden group-[.active]:block rounded border border-gray-200 px-1 py-0 text-[9px] uppercase font-medium">
                  Selecionado
                </span>
              </strong>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}