import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './Wrapper.module.scss';

// #region ICONS
import logoImage from '../../assets/logo-icon-sem-fundo-1.png';
import iconMenu from '../../assets/icons/menu.svg';
import iconOffice from '../../assets/icons/office.svg';
import iconHelp from '../../assets/icons/help.svg';
import iconProfile from '../../assets/icons/profile.svg';
// #endregion ICONS
import logo from '../../assets/default-client-logo.png';
import { Dropdown } from "../utils/Dropdown";
import { shortclass } from "../../styles/styles";
import { PossiblePermissions, User } from '../../types';
import { ClientProps, DisabledType } from '.';
import { useNavigate } from 'react-router-dom';

export const HeaderAside = ({
  user,
  module_name,
  disableds,
  clients,
  handleChangeClient,
  handleHelp,
  handleLogout
} : {
  user: User,
  module_name?: string,
  disableds: DisabledType,
  clients: ClientProps[],
  handleChangeClient: (client_id: string, client_name: string) => void,
  handleHelp: () => void,
  handleLogout: () => void
}) => {
  const navigate = useNavigate();

  const [canAccessAdminPanel, setCanAccessAdminPanel] = useState(false);
  useEffect(() => {
    if(!user) return;

    setCanAccessAdminPanel(
      !!(user.permitions_slug && user.permitions_slug.includes(PossiblePermissions.ADMIN))
    );
  }, [user])
  return (
    <header className={`${styles.header} bg-gradient-primary`}>
      <div className={styles.legend}>
        <div className="flex-1 text-center uppercase font-semibold text-xs opacity-70">{ user.name }</div>
        <div className="flex-1 text-center uppercase font-semibold text-xs opacity-70">{ module_name ?? 'Portal Ivrim' }</div>
        <div className="flex-1 text-center uppercase font-semibold text-xs opacity-70">{ user.client_name ?? '' }</div>
      </div>
      <ul className={styles.navbar}>
        <li className={
          disableds.includes('menu') ? styles.disabled :''
        }>
          { disableds.includes('menu') ? (
            <img className={styles.headerIcon} src={iconMenu}/>
          ) : (              
            <Dropdown orientation="left" trigger={(
              <img className={styles.headerIcon} src={iconMenu}/>
            )}>
              <button
                type="button"
                className={shortclass.dropdownItem}
                disabled
              >Faturamento e Contas a Receber </button>
              <button
                type="button"
                className={shortclass.dropdownItem}
                onClick={() => navigate('/compras-e-contas-a-pagar')}
                disabled={!user?.permitions_slug?.includes(PossiblePermissions.CONTAS_A_PAGAR)}
              >Compras e Contas a Pagar</button>
              <button
                type="button"
                className={shortclass.dropdownItem}
                onClick={() => navigate('/gestao')}
                disabled={!user?.permitions_slug?.includes(PossiblePermissions.GESTAO)}
              >Gestão</button>
              <button
                type="button"
                className={shortclass.dropdownItem}

                disabled
              >Soluções I.A</button>
              {canAccessAdminPanel ? (
                <Link
                  to="/painel-adm"
                  className={shortclass.dropdownItem}
                >Painel Adm</Link>
              ):(
                <button
                  type="button"
                  className={shortclass.dropdownItem}
                  disabled
                >Painel Adm</button>
              )}
              
            </Dropdown>
          )}
        </li>
        <li className={
          disableds.includes('office') ? styles.disabled :''
        }>
          <Dropdown orientation="left" trigger={(
            <img
              className={styles.headerIcon}
              src={iconOffice}
            />
          )}>
            {clients.map(client => (
              <button
                key={client.id}
                type="button"
                className={shortclass.dropdownItem}
                onClick={() => client.active ? {} : handleChangeClient(client.id, client.nome_fantasia)}
              >
                <div className="flex items-center">
                  <img
                    alt={client.nome_fantasia}
                    className="rounded-full object-cover w-6 h-6"
                    src={client.picture ?? logo}
                    onError={(e) => {
                      let img = e.target as HTMLImageElement;
                      img.src = logo;
                    }}
                  />
                  <span className="ml-3 flex-1 text-ellipsis overflow-hidden whitespace-nowrap block">
                    {client.nome_fantasia}
                  </span>
                  {client.active && (
                    <span className="ml-3 inline-flex items-center justify-center rounded bg-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500 dark:bg-gray-700 dark:text-gray-400">
                      Selecionado
                    </span>
                  )}
                </div>
              </button>
            ))}
          </Dropdown>
        </li>
        <li className={`${styles.headerLogo} hidden xs:block`}>
          <Link to="/">
            <svg viewBox="0 0 149 75" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_iii_41_3)">
                <path
                  d="M148.5 75C148.5 75 114.921 75 73.5 75C32.0786 75 0 75 0 75C0 33.5786 32.0786 0 73.5 0C114.921 0 148.5 33.5786 148.5 75Z"
                  fill="#D3D3D3" />
              </g>
              <defs>
                <filter id="filter0_iii_41_3" x="0" y="0" width="148.5" height="79" filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_41_3" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="effect1_innerShadow_41_3" result="effect2_innerShadow_41_3" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="effect2_innerShadow_41_3" result="effect3_innerShadow_41_3" />
                </filter>
              </defs>
            </svg>
            <img src={logoImage} alt="Logo Leão da Ivrim"/>
          </Link>
        </li>
        <li className={
          disableds.includes('help') ? styles.disabled :''
        }>
          <button type="button" onClick={handleHelp}>
            <img className={styles.headerIcon} src={iconHelp}/>
          </button>
        </li>
        <li className={
          disableds.includes('profile') ? styles.disabled :''
        }>
          <Dropdown
            trigger={<img className={styles.headerIcon} src={user.picture ?? iconProfile}/>}
          >
            <Link
              className={shortclass.dropdownItem}
              to={'/perfil'}
            >Perfil</Link>
            <button
              type="button"
              className={shortclass.dropdownItem}
              onClick={handleLogout}
            >Sair</button>
          </Dropdown>
        </li>
      </ul>
    </header>
  );
}