import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import {useForm} from 'react-hook-form';
import { sendEmail } from "../../services/sendEmail";
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";

import logoIcon from "../../assets/logo-icon-sem-fundo-1.png";
import background from "../../assets/background-login.jpg";
import { shortclass } from "../../styles/styles";
import { Modal } from "../../components/utils/Modal";
import { EyeIcon, LockIcon, MailIcon } from "../../components/SvgIcons";

interface formType {
  nome: string
  empresa: string
  email: string
  telefone: string
}

export const Login = () => {
  const { toast } = useNotify();
  const { signIn } = useAuth();

  const navigate = useNavigate();
  const [modal, setModal] = useState<boolean>(false);
  const [UseEmail, setUserEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const {register, handleSubmit, reset} = useForm<formType>({
    defaultValues:{
      empresa:'',
      nome:'',
      telefone:'',
      email:''

    }
  });

  const handleSave = async (formValues: formType) => {
    await toast.promise(
      sendEmail({
        nome: formValues.nome,
        empresa: formValues.empresa,
        email: formValues.email,
        telefone: formValues.telefone
      }),{
        pending: 'Enviando email',
        success: 'Email enviado',
        error: 'Falha ao enviar. Tente novamente mais tarde'
      }
    ).then(() => {            
      setModal(false);
      reset();
    });
  }

  async function handleUserAuthentication(e: FormEvent) {
    e.preventDefault()
    if(!UseEmail){
      toast.warning('Preencha o seu email');
      document.getElementById('input-login-email')?.focus();
      return;
    }
    if(!password) {
      toast.warning('Preencha sua senha');
      document.getElementById('input-login-password')?.focus();
      return;
    }

    const data = {
      email: UseEmail,
      password: password,
      remember_me: !!document.querySelector('#check-login-remember-me:checked')
    }
    
    await signIn(data).then(() => {
      navigate('/')
    }).catch((error) => {
      toast.error(error.message);
    })
  }

  return (
    <div className="
      h-full flex
      bg-gradient-primary
      bg-cover bg-no-repeat bg-center
      min-h-[100vh]
    " style={{
      backgroundImage: `url(${background})` 
    }}>
      <div className="
        min-h-full min-w-full sm:min-w-max max-w-[1500px]
        container grid items-center
        mx-auto
        py-12 p-0 xs:px-2 sm:px-8 md:px-10 lg:px-12
      ">
        <div className="
          bg-semi-transparent xs:rounded-lg
          m-auto md:m-0 space-y-8
          px-2 xxs:px-4 xs:px-6 py-10
          w-full xs:w-[26rem]

        " style={{ maxWidth: "100vw" }}>
          <div>
            <h2 className="text-center text-2xl xs:text-3xl font-bold tracking-tight text-gray-900">Portal de Soluções Ivrim</h2>
            <img
              className="mx-auto h-10 xs:h-12 w-auto mt-4"
              src={logoIcon}
              alt="Your Company"
            />
          </div>
          <form
            className="mt-6 space-y-6"
            method="POST"
            onSubmit={handleUserAuthentication}
          >
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="input-login-email" className="sr-only">Email</label>
                <div className="relative">
                  <span className="absolute left-0 top-0 bottom-0 p-2 z-50 text-gray-700/80">
                    <MailIcon width="18"/>
                  </span>
                  <input
                    id="input-login-email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={event => setUserEmail(event.target.value)}
                    value={UseEmail}
                    required
                    className={`${shortclass.input.roundedTop} pl-8`}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Senha</label>
                <div className="relative">
                  <span className="absolute left-0 top-0 bottom-0 p-2 z-50 text-gray-700/80">
                    <LockIcon width="18"/>
                  </span>
                  <input
                    id="input-login-password"
                    name="password"
                    type={showPassword ? 'text':'password'}
                    autoComplete="current-password"
                    onChange={event => setPassword(event.target.value)}
                    value={password}
                    required
                    className={`${shortclass.input.roundedBottom} pl-8`}
                    placeholder="Senha"
                  />
                  <button
                    type="button"
                    className="absolute right-0 top-0 bottom-0 p-2 z-50 text-gray-700/80"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <EyeIcon eyeOpen={!showPassword} width="18"/>
                  </button>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between flex-col xs:flex-row">
              <div className="flex items-center">
                <input
                  name="remember-me"
                  id="check-login-remember-me"
                  type="checkbox"
                  className={shortclass.checkbox.primary}
                  value="true"
                />
                <label
                  htmlFor="check-login-remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >Manter conectado</label>
              </div>
              <div className="text-sm">
                <button
                  type="button"
                  onClick={() => { setModal(true); }}
                  className="font-medium text-primary-600 hover:text-primary-500"
                >Não consegue logar?</button>
              </div>
            </div>
            <div>
              <button type="submit" className={`group relative w-full ${shortclass.button.primary}`}>
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-primary-400 group-hover:text-primary-300"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                  ><path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" /></svg>
                </span>
                Entrar
              </button>
            </div>
          </form>
        </div>
      </div>

      <Modal
        isOpen={modal}
        setIsOpen={setModal}
        options={{
          title: "Solicitação de Suporte",
          actionButton: {
            theme: 'primary',
            text: 'Enviar Solicitação',
            onClick: () => {
              let btnSubmitForm = document.getElementById('btn-submit-form-recorver-password') as HTMLButtonElement;
              if(btnSubmitForm) btnSubmitForm.click();
            },
            autoClose: false
          }
        }}
      >
        <form
          id="form-recorver-password"
          onSubmit={handleSubmit(handleSave)}
          className="mt-5"
        >
          <div className="
            rounded-md shadow-sm 
            sm:columns-2
            mx-auto
          ">
            <div className="mb-4">
              <label htmlFor="input-recovery-pass-name" className="sr-only">Nome</label>
              <input
                type="text"
                {...register('nome')}
                id="input-recovery-pass-name"
                required={true}
                placeholder="Nome"
                className={shortclass.input.roundedTop}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="input-recovery-pass-email" className="sr-only">Email</label>
              <input
                type="email"
                {...register('email')}
                required={true}
                placeholder="Email"
                className={shortclass.input.roundedBottom}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="input-recovery-pass-enterprise" className="sr-only">Empresa</label>
              <input
                type="text"
                {...register('empresa')}
                required={true}
                placeholder="Empresa"
                className={shortclass.input.roundedTop}
              />
            </div>
            <div className="mb-4">
            <label htmlFor="input-recovery-pass-phone" className="sr-only">Telefone</label>
              <input
                type="tel"
                {...register('telefone')}
                required={true}
                placeholder="Telefone"
                inputMode="numeric"
                className={shortclass.input.roundedBottom}
              />
            </div>
            <button type="submit" className="d-none" id="btn-submit-form-recorver-password"></button>
          </div>
        </form>
      </Modal>
    </div>
  );
}