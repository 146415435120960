import { DocumentTemplateType, ResultAndResponse } from "../types";
import { api, headerBearer } from "./api";

export const getModels = async (token: string) : Promise<DocumentTemplateType[]> => {
  const response = await api.get('/models', headerBearer(token))
  return response.data;
}
export const uploadModel = async (data: FormData, token: string) : Promise<ResultAndResponse> => {
  try{
    const response = await api.post('/models',  data,  headerBearer(token));
    return {
      result: response.data.result,
      response: response.data.result ? 'Upload realizado com sucesso' : response.data.response
    }
  }catch(e){
    console.error(e);
    return {
      result: false,
      response: 'Houve um erro ao fazer upload dos modelos'
    }
  }
}
export const deleteModel = async (src: string, token: string) : Promise<ResultAndResponse> => {
  const { data } = await api.put('/delete-model',{ src }, headerBearer(token));

  return {
    result: data.result,
    response: data.result ? 'Excluido com sucesso' : data.response
  };
}